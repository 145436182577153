import { SearchRange } from './useSearch';
import { Options } from '../../../../components/forms';
import { Store } from '../../../../store';
import { Tag } from 'lib';

export const getFilterTargetTags = (
  range: SearchRange,
  store: Store
): Tag[] => {
  const teamIds =
    range.type === 'allInboxes' ? store.joinedTeamIds : [range.teamId];
  return store.tags.filter((tag) => teamIds.includes(tag.teamId));
};

export const getFilterTagOptions = (
  range: SearchRange,
  store: Store
): Options<string> => {
  const targetTags = getFilterTargetTags(range, store);
  const tags = targetTags.map((tag) => ({
    value: tag.id,
    label: tag.name,
  }));
  return [{ value: '', label: '' }, ...tags];
};
