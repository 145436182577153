import { useStore } from '../../hooks/useStore';
import { useHistory, useParams } from 'react-router-dom';
import { SearchQuery } from '../../store/search';
import { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { HorizontalScrollArea } from '../../components/basics/HorizontalScrollArea';
import { SearchRangeSelect } from '../Common/MainNav/search/SearchRangeSelect';
import { Button, Icon } from '../../components/basics';
import { Close } from '../../components/icons';
import { useAtomValue, useSetAtom } from 'jotai';
import { usersAtom } from '../../atoms/firestore/user';
import {
  getSearchRange,
  searchRangeToQuery,
} from '../Common/MainNav/search/searchRange';
import {
  dirtyResetAtom,
  Tray,
  trayOptions,
} from '../Common/MainNav/search/useSearch';
import { tv } from 'tailwind-variants';
import { Select } from '../../components/forms';
import { pick } from 'lodash';
import {
  openSearchFilterAtom,
  SearchFilterData,
} from '../Common/MainNav/MainSearch';
import { getFilterTargetTags } from '../Common/MainNav/search/tag';
import {
  getFilterMemberOptions,
  getFilterTargetMembers,
} from '../Common/MainNav/search/member';
import { SearchStatusSelect } from '../Common/MainNav/search/SearchStatusSelect';
import { MessageStatus } from 'lib';
import { allTeamStatusesInGroupAtom } from '../../atoms/firestore/customStatuses';
import { getFilterCustomStatuses } from '../Common/MainNav/search/customStatuses';
import { SearchTagSelect } from '../Common/MainNav/search/SearchTagSelect';

const selectFilterItem = tv({
  base: 'mr-2 h-7 min-w-fit gap-0.5 bg-sea-500 text-[12px] font-bold text-white',
});

export const FilterDisplay = observer((): JSX.Element => {
  const store = useStore();
  const { searchStore } = store;
  const { tray, query: queryString } = useParams<{
    tray: string;
    query: string;
  }>();
  const history = useHistory();

  const users = useAtomValue(usersAtom);
  const resetFilter = useSetAtom(dirtyResetAtom);

  const [, allCustomStatuses] = useAtomValue(allTeamStatusesInGroupAtom);

  const query: SearchQuery = useMemo(() => {
    try {
      return JSON.parse(decodeURIComponent(queryString));
    } catch (e) {
      console.error(e);
      history.push('/me/drafts');
      return {};
    }
  }, [queryString]);
  const searchRange = useMemo(() => getSearchRange(query), [query]);

  const date = useMemo(() => {
    if (!query.before && !query.after) {
      return undefined;
    }
    let s = '';
    if (query.after) {
      s += query.after;
    }
    s += ' ~ ';
    if (query.before) {
      s += query.before;
    }
    return s;
  }, [query.before, query.after]);

  const updateQueryAndSearch = (
    update: Partial<SearchQuery>,
    updateTray?: Tray
  ) => {
    searchStore.query = {
      ...searchStore.query,
      ...searchRangeToQuery(searchRange),
      ...update,
    };
    history.push(
      `/search/${updateTray ?? tray}/${encodeURIComponent(
        JSON.stringify(searchStore.query)
      )}`
    );
  };

  const updateTrayAndSearch = (tray: Tray) => {
    if (tray !== 'sent') {
      updateQueryAndSearch({}, tray);
      return;
    }

    searchStore.query = {
      ...pick(searchStore.query, 'keywords'),
      ...searchRangeToQuery(searchRange),
    };

    history.push(
      `/search/${tray}/${encodeURIComponent(JSON.stringify(searchStore.query))}`
    );
  };

  useEffect(() => {
    if (store.inboxesLoading || store.tagsLoading) {
      return;
    }
    searchStore.query = query;
    switch (tray) {
      case 'messages':
        searchStore.searchMessages({});
        break;
      case 'sent':
        searchStore.searchSent({});
        break;
      case 'deleted':
        searchStore.searchMessages({
          deleted: true,
        });
        break;
    }
  }, [store.inboxesLoading, store.tagsLoading, query, tray]);

  const tag = query.tags ? store.getTag(query.tags[0]) : undefined;
  const assignee = useMemo(() => {
    if (query.assignee === null) {
      return null;
    }
    return query.assignee
      ? users.find((u) => u.id === query.assignee)
      : undefined;
  }, [users, query.assignee]);

  return (
    <HorizontalScrollArea className="hidden h-12 items-center bg-white text-sm sm:flex">
      <div className="items-center gap-2 whitespace-nowrap pl-4 pr-4 lg:pl-2">
        <div className="inline-block">
          <Select
            value={tray}
            onChange={(tray) => updateTrayAndSearch(tray as Tray)}
            options={trayOptions}
            variants={{ rounded: 'lg', border: false }}
            className={selectFilterItem()}
            withPortal
          />
        </div>
        <div className="inline-block">
          <SearchRangeSelect
            value={searchRange}
            onChange={(searchRange) => {
              const update = searchRangeToQuery(searchRange);

              // 現在設定されているタグが変更先のInboxに存在しなかったら条件から外す
              if (tag) {
                const targetTags = getFilterTargetTags(searchRange, store);
                if (targetTags.every((t) => t.id !== tag.id)) {
                  update.tags = undefined;
                }
              }

              // 現在設定されている担当者が変更先のInboxに存在しなかったら条件から外す
              if (assignee) {
                const targetMembers = getFilterTargetMembers(
                  searchRange,
                  store
                );
                if (targetMembers.every((m) => m.id !== assignee.id)) {
                  update.assignee = undefined;
                }
              }

              // 現在設定されているステータスが変更先のチームに存在しなかったら条件から外す
              if (
                query.status &&
                query.status !== MessageStatus.Unprocessed &&
                query.status !== MessageStatus.Processed
              ) {
                const targetStatuses = getFilterCustomStatuses(
                  searchRange,
                  allCustomStatuses
                );
                if (targetStatuses.every((s) => s.id !== query.status)) {
                  update.status = undefined;
                }
              }

              updateQueryAndSearch(update);
            }}
            variants={{ rounded: 'lg', border: false }}
            className={selectFilterItem()}
            withPortal
          />
        </div>
        {query.status && (
          <div className="inline-block">
            <SearchStatusSelect
              value={query.status}
              onChange={(status) =>
                updateQueryAndSearch({ status: status || undefined })
              }
              range={searchRange}
              variants={{ rounded: 'lg', border: false }}
              renderLabel={(_value, label) => (
                <div className="flex items-center gap-0.5">
                  <span>ステータス</span>
                  <span>{label}</span>
                </div>
              )}
              className={selectFilterItem()}
            />
          </div>
        )}
        {query.from && (
          <FilterItem
            focusId="from"
            label="From"
            value={query.from}
            onDelete={() => updateQueryAndSearch({ from: undefined })}
          />
        )}
        {query.to && (
          <FilterItem
            focusId="to"
            label="To"
            value={query.to}
            onDelete={() => updateQueryAndSearch({ to: undefined })}
          />
        )}
        {query.subjectOrText && (
          <FilterItem
            focusId="subjectOrText"
            label="件名,本文"
            value={query.subjectOrText}
            onDelete={() => updateQueryAndSearch({ subjectOrText: undefined })}
          />
        )}
        {tag && (
          <div className="inline-block">
            <SearchTagSelect
              range={searchRange}
              value={tag.id}
              onChange={(tagId) =>
                updateQueryAndSearch({
                  tags: tagId ? [tagId] : undefined,
                })
              }
              variants={{ rounded: 'lg', border: false }}
              renderLabel={(_value, label) => (
                <div className="flex items-center gap-0.5">
                  <span>タグ</span>
                  <span>{label}</span>
                </div>
              )}
              className={selectFilterItem()}
              withPortal
            />
          </div>
        )}
        {assignee !== undefined && (
          <div className="inline-block">
            <Select
              value={assignee?.id ?? null}
              onChange={(assignee) =>
                updateQueryAndSearch({
                  assignee: assignee === '' ? undefined : assignee,
                })
              }
              options={getFilterMemberOptions(searchRange, store)}
              variants={{ rounded: 'lg', border: false }}
              renderLabel={(_value, label) => (
                <div className="flex items-center gap-0.5">
                  <span>担当者</span>
                  <span>{label}</span>
                </div>
              )}
              className={selectFilterItem()}
              withPortal
            />
          </div>
        )}
        {date && (
          <FilterItem
            focusId="after"
            label="受信日時"
            value={date}
            onDelete={() =>
              updateQueryAndSearch({ before: undefined, after: undefined })
            }
          />
        )}
        {query.hasAttachments && (
          <FilterItem
            focusId="attachmentsFilename"
            label="添付ファイル"
            value={query.attachmentsFilename || 'あり'}
            onDelete={() =>
              updateQueryAndSearch({
                hasAttachments: false,
                attachmentsFilename: undefined,
              })
            }
          />
        )}
        <Button
          variant="text"
          className="mr-10 h-7 text-[12px] no-underline"
          onClick={() => resetFilter()}
        >
          検索を解除
        </Button>
      </div>
    </HorizontalScrollArea>
  );
});

type FilterItemProps = {
  focusId: keyof SearchFilterData;
  label: string;
  value: string;
  onDelete: () => void;
};

const FilterItem = ({ focusId, label, value, onDelete }: FilterItemProps) => {
  const openSearchFilter = useSetAtom(openSearchFilterAtom);
  return (
    <div className="mr-2 inline-flex h-7 items-center rounded-lg bg-sea-500 text-[12px] font-bold text-white">
      <button
        type="button"
        className="m-0 flex h-7 cursor-pointer select-none items-center gap-2 whitespace-nowrap rounded-l-lg bg-transparent p-0 pl-2 pr-0.5 font-bold"
        onClick={() => openSearchFilter(focusId)}
      >
        <div>{label}</div>
        <div>{value}</div>
      </button>
      <button
        type="button"
        className="flex h-7 w-7 cursor-pointer items-center justify-center rounded-lg bg-transparent p-0"
        onClick={() => onDelete()}
      >
        <Icon icon={Close} size={14} />
      </button>
    </div>
  );
};
