import {
  ComponentProps,
  ComponentPropsWithoutRef,
  FC,
  forwardRef,
  useRef,
  useState,
} from 'react';
import { twMerge } from 'tailwind-merge';
import { Avatar } from '../../../../../components/basics/Avatar/Avatar';
import { Button, Icon } from '../../../../../components/basics';
import { ContentCopy } from '../../../../../components/icons';
import { Tooltip } from '../../../../../components/basics/Tooltip/Tooltip';

type Props = ComponentPropsWithoutRef<'div'> & {
  name: string;
  email: string;
  backgroundColor?: string;
  avatarUrl?: string;
  onOpenDetail: () => void;
};

export const FromHoverCard = forwardRef<HTMLDivElement, Props>(
  (
    {
      name,
      email,
      backgroundColor,
      avatarUrl,
      onOpenDetail,
      className,
      ...props
    },
    ref
  ) => {
    return (
      <div
        className={twMerge(
          className,
          'flex w-[300px] flex-col gap-4 rounded-lg bg-white p-4 text-sm shadow-dropdown'
        )}
        {...props}
        ref={ref}
      >
        <div className="grid grid-cols-[auto_1fr] items-center gap-4">
          <Avatar
            size={40}
            name={name}
            baseColor={backgroundColor}
            src={avatarUrl}
            showNameOnHover={false}
          />
          <div className="grid max-w-full grid-rows-[auto_auto] leading-6">
            <div className="truncate text-[16px] font-bold">{name}</div>
            <div className="grid grid-cols-[1fr_auto] gap-2">
              <div className="truncate" title={email}>
                {email}
              </div>
              <CopyButton text={email} />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <Button
            variant="outlined"
            className="h-8 w-[72px] rounded-lg text-[12px]"
            onClick={() => onOpenDetail()}
          >
            詳細
          </Button>
          <div className="flex gap-3">
            {/* 将来追加するボタン */}
            {/*<Tooltip content="メールを作成">*/}
            {/*  <CardButton icon={Mail} />*/}
            {/*</Tooltip>*/}
            {/*<Tooltip content="履歴を確認">*/}
            {/*  <CardButton icon={History} />*/}
            {/*</Tooltip>*/}
          </div>
        </div>
      </div>
    );
  }
);

type CardButtonProps = Pick<ComponentProps<typeof Icon>, 'icon'> &
  ComponentPropsWithoutRef<'button'>;

const CardButton: FC<CardButtonProps> = forwardRef<
  HTMLButtonElement,
  CardButtonProps
>(({ icon, className, ...props }, ref) => {
  return (
    <button
      type="button"
      className={twMerge(
        "relative m-0 h-[24px] w-[24px] cursor-pointer bg-transparent p-0 before:absolute before:inset-[-6px] before:z-[-1] before:rounded-full before:content-[''] hover:before:bg-[rgba(0,0,0,0.05)]",
        className
      )}
      {...props}
      ref={ref}
    >
      <Icon icon={icon} size={24} />
    </button>
  );
});

type CopyButtonProps = {
  text: string;
};

const CopyButton = ({ text }: CopyButtonProps) => {
  const [copied, setCopied] = useState(false);
  const timeoutRef = useRef<number>();
  const onCopy = async () => {
    setCopied(true);
    await navigator.clipboard.writeText(text);
    timeoutRef.current = setTimeout(() => setCopied(false), 1000) as never;
  };
  return (
    <Tooltip content={copied ? '完了' : 'コピー'} open={copied || undefined}>
      <CardButton icon={ContentCopy} onClick={() => onCopy()} />
    </Tooltip>
  );
};
