import * as Popover from '@radix-ui/react-popover';
import React, { ReactNode, useState } from 'react';
import { clamp, range } from 'lodash';
import { tv } from 'tailwind-variants';

type Props = {
  disabled?: boolean;
  onInsert: (rows: number, cols: number) => void;
  button: ReactNode;
};

type TableSize = { rows: number; cols: number };

const cell = tv({
  base: 'm-0 h-4 w-4 cursor-pointer border border-sumi-200 bg-transparent p-0',
  variants: {
    active: {
      true: 'border-none bg-sea-200',
    },
  },
});

const MIN_SIZE = 2;
const MAX_SIZE = 5;

export const ToolbarTablePopover = ({ onInsert, disabled, button }: Props) => {
  const [size, setSize] = useState<TableSize>({
    rows: 1,
    cols: 1,
  });
  const [displaySize, setDisplaySize] = useState<TableSize>({
    rows: MIN_SIZE,
    cols: MIN_SIZE,
  });
  const reset = () => {
    setSize({ rows: 1, cols: 1 });
    setDisplaySize({ rows: MIN_SIZE, cols: MIN_SIZE });
  };
  const select = (row: number, col: number) => {
    setSize({ rows: row, cols: col });
    setDisplaySize({
      rows: clamp(row + 1, MIN_SIZE, MAX_SIZE),
      cols: clamp(col + 1, MIN_SIZE, MAX_SIZE),
    });
  };
  return (
    <Popover.Root onOpenChange={() => reset()}>
      {button}
      <Popover.Content
        className="z-50 flex flex-col gap-0.5 rounded-lg bg-white p-2 shadow-dropdown"
        collisionPadding={16}
      >
        {range(1, displaySize.cols + 1).map((col) => (
          <div key={col} className="flex gap-0.5">
            {range(1, displaySize.rows + 1).map((row) => (
              <button
                key={row}
                type="button"
                className={cell({
                  active: col <= size.cols && row <= size.rows,
                })}
                onPointerEnter={() => select(row, col)}
                onFocus={() => select(row, col)}
                onClick={() => onInsert(row, col)}
              />
            ))}
          </div>
        ))}
      </Popover.Content>
    </Popover.Root>
  );
};
