import { computed, makeObservable, observable } from 'mobx';
import moment from 'moment';

import { eventNames, logEvent } from '../analytics';
import { searchFunction } from '../functions';
import { db9 } from '../firebase';
import type { Store } from './index';
import {
  createMessageLike,
  Impression,
  Message as MessageEntity,
  MessageData,
  MessageLatestComment,
  MessageLike,
  sentConverter,
  threadConverter,
  ThreadData,
} from 'lib';
import lStorage, { storageKeys } from '../localStorage';
import { isEqual } from 'lodash';
import {
  doc,
  DocumentChangeType,
  DocumentReference,
  Timestamp,
} from 'firebase/firestore';
import { companyDoc } from '../firestore';

const PAGE_SIZE = 40;

interface MessageSource {
  assignee: string | null;
  attachments: any[];
  date: string;
  deleted: boolean;
  from: any;
  id: string;
  isAutoReplied: boolean;
  comments: any[];
  readers: any;
  status: string;
  subject: string;
  tags: string[];
  teamId: string;
  text: string;
  threadId: string;
  to?: string[];
}

type ThreadSource = ThreadData & {
  createdAt: string;
  date: string;
  updatedAt: string;
  latestComment:
    | (Omit<MessageLatestComment, 'messageRef'> & {
        createdAt: string;
        updatedAt: string;
      })
    | null;
};

type SearchFunctionResult =
  | {
      threadView: false;
      total: number;
      hits: { id: string; data: MessageSource }[];
    }
  | {
      threadView: true;
      total: number;
      hits: { id: string; data: ThreadSource }[];
    };

/** An item to be rendered in the MessageList. */
export interface SearchMessage {
  assignee: string | null;
  attachments: any[];
  date: moment.Moment;
  deleted: boolean;
  from: any;
  fromText: string;
  id: string;
  isAutoReplied: boolean;
  latestComment: any;
  readers: any;
  status: string;
  impression?: Impression;
  subject: string;
  tags: string[];
  teamId: string;
  text: string;
  threadId: string;
  to?: string[];
  data: {
    date: Timestamp;
  };
  asMessage: () => MessageEntity;
}

export type SearchQuery = {
  keywords?: string[];
  teamIds?: string[];
  inboxId?: string;
  inbox?: boolean;
  sender?: string;
  status?: string;
  from?: string;
  to?: string;
  subjectOrText?: string;
  tags?: string[];
  assignee?: string | null;
  after?: string;
  before?: string;
  hasAttachments?: boolean;
  attachmentsFilename?: string;
};

export type SearchHistoryEntry = {
  tray: string;
  query: SearchQuery;
};

export class SearchStore {
  searching = false;
  hasMore = true;
  unsortedMessages: any[] = [];
  unsortedSent: any[] = [];
  query: SearchQuery = {};
  inSearch = false;
  timeouts = new Map<string, NodeJS.Timeout>();
  private offset = 0;

  constructor(private rootStore: Store) {
    makeObservable<SearchStore, '_history'>(this, {
      searching: observable,

      unsortedMessages: observable,
      sortedMessages: computed,

      unsortedSent: observable,
      sortedSent: computed,

      query: observable,
      inSearch: observable,

      _history: observable,
    });
  }

  private _history: SearchHistoryEntry[] = this.getHistoryFromStorage();

  get history(): SearchHistoryEntry[] {
    return this._history;
  }

  get sortedMessages(): SearchMessage[] {
    return this.unsortedMessages
      .slice()
      .sort((a, b) => b.date.valueOf() - a.date.valueOf());
  }

  get sortedSent(): SearchMessage[] {
    return this.unsortedSent
      .slice()
      .sort((a, b) => b.date.valueOf() - a.date.valueOf());
  }

  _sourceToMessage({
    assignee,
    attachments,
    date,
    deleted,
    from,
    id,
    isAutoReplied,
    comments,
    readers,
    status,
    subject,
    tags,
    teamId,
    text,
    threadId,
    to,
  }: MessageSource): SearchMessage {
    const message = {
      assignee: assignee || null,
      attachments,
      date: moment(date),
      deleted,
      from,
      fromText: from?.text || '',
      id,
      isAutoReplied,
      latestComment: comments?.slice(-1)?.[0],
      readers,
      status,
      subject: subject || '',
      tags: tags || [],
      teamId,
      text: text || '',
      threadId,
      to: to || [],
      data: {
        date: Timestamp.fromDate(new Date(date)),
      },
    } as SearchMessage;
    message.asMessage = () => message as unknown as MessageEntity;
    return message;
  }

  threadSourceToMessage(
    id: string,
    deleted: boolean,
    data: ThreadSource
  ): MessageLike {
    const ref = companyDoc('threads', id, threadConverter);
    const messageLike = createMessageLike(ref as never, {
      ...data,
      createdAt: Timestamp.fromDate(new Date(data.createdAt)),
      date: Timestamp.fromDate(new Date(data.date)),
      updatedAt: Timestamp.fromDate(new Date(data.updatedAt)),
      latestComment: data.latestComment
        ? {
            ...data.latestComment,
            createdAt: Timestamp.fromDate(
              new Date(data.latestComment.createdAt)
            ),
            updatedAt: Timestamp.fromDate(
              new Date(data.latestComment.updatedAt)
            ),
            messageRef: doc(
              db9,
              'companies',
              this.rootStore.signInCompany,
              'messages',
              data.latestComment.messageId
            ) as DocumentReference<MessageData>,
          }
        : null,
    });
    return deleted ? messageLike.toDeletedThread() : messageLike;
  }

  searchMessagesByDomain = async (
    teamId: string,
    domain: string
  ): Promise<SearchMessage> => {
    const queryParam = {
      companyId: this.rootStore.signInCompany,
      teamIds: teamId ? [teamId] : this.rootStore.joinedTeamIds,
      type: 'messages',
      from: '@' + domain,
      deleted: false,
      offset: 0,
      limit: 100,
    };
    const result = await searchFunction({
      query: queryParam,
    });
    return result.data.hits.map((hit: any) => this._sourceToMessage(hit.data));
  };

  getSearchParams = (offset: number, deleted: boolean) => {
    const query = this.query;

    const teamIds = query.teamIds;
    const teamIdsParam = teamIds?.length
      ? teamIds
      : this.rootStore.joinedTeamIds;

    const queryParam: Record<string, any> = {
      companyId: this.rootStore.signInCompany,
      offset,
      limit: PAGE_SIZE,
      deleted,
      type: 'messages',
      ...query,
      teamIds: teamIdsParam,
      threadView: this.rootStore.isInThreadView,
      ignoreThreadIds: this.rootStore.isInThreadView
        ? this.unsortedMessages.map((m) => m.id)
        : [],
    };
    if (query.inbox) {
      queryParam.inboxTags = this.rootStore.tags
        .filter((tag) => tag.isInbox)
        .filter((tag) => teamIdsParam.includes(tag.teamId))
        .map((tag) => tag.id);
    }
    return Object.fromEntries(
      Object.entries(queryParam).filter(([_k, v]) => v !== undefined)
    );
  };

  searchMessages = async ({
    deleted = false,
    withOffset = false,
  }): Promise<void> => {
    if (!this.inSearch) {
      // パラメータが指定されていない場合は検索しない
      return;
    }

    let offset = 0;
    if (withOffset) {
      offset = this.offset;
    } else {
      this.hasMore = true;
      this.unsortedMessages = [];
    }

    this.searching = true;
    const queryParam = this.getSearchParams(offset, deleted);
    const { data: result } = (await searchFunction({
      query: queryParam,
    })) as { data: SearchFunctionResult };
    logEvent(eventNames.search_messages);

    // offset + limitが検索結果合計数より多いもしくは等しい場合、次ページがない
    if (offset + PAGE_SIZE >= result.total) {
      this.hasMore = false;
    }
    this.offset = offset + result.hits.length;

    if (!result.hits.length) {
      this.searching = false;
      return;
    }

    if (result.threadView) {
      result.hits
        .filter(({ id }) => this.unsortedMessages.every((x) => x.id !== id))
        .forEach(({ id, data }) => {
          this.unsortedMessages.push(
            this.threadSourceToMessage(id, deleted, data)
          );
        });
      this.searching = false;
    } else {
      const messages = result.hits.map((hit: any) =>
        this._sourceToMessage(hit.data)
      );

      for (const message of messages) {
        if (this.unsortedMessages.every((x) => x.id !== message.id)) {
          this.unsortedMessages.push(message);
        }
      }

      this.searching = false;
    }
  };

  searchSent = async ({ withOffset = false }): Promise<void> => {
    if (!this.inSearch) {
      // パラメータが指定されていない場合は検索しない
      return;
    }

    let offset = 0;
    if (withOffset) {
      offset = this.offset;
    } else {
      this.hasMore = true;
      this.unsortedSent = [];
    }

    this.searching = true;
    const teamIds = this.query.teamIds;

    const queryParam = {
      companyId: this.rootStore.signInCompany,
      offset,
      limit: PAGE_SIZE,
      type: 'sent',
      ...this.query,
      teamIds: teamIds?.length ? teamIds : this.rootStore.joinedTeamIds,
    };

    const result = await searchFunction({
      query: queryParam,
    });
    logEvent(eventNames.search_messages);

    // offset + limitが検索結果合計数より多いもしくは等しい場合、次ページがない
    if (offset + PAGE_SIZE >= result.data.total.value) {
      this.hasMore = false;
    }
    this.offset = offset + result.data.hits.length;

    if (!result.data.hits.length) {
      this.searching = false;
      return;
    }

    const messages = result.data.hits.map((hit: any) =>
      this._sourceToMessage(hit.data)
    );

    for (const message of messages) {
      if (this.unsortedSent.every((x) => x.id !== message.id)) {
        this.unsortedSent.push({
          ...message,
          ref: companyDoc('sent', message.id, sentConverter),
        });
      }
    }

    this.searching = false;
  };

  onMessageChange(
    changedMessage: MessageLike,
    changeType: DocumentChangeType,
    deleted: boolean
  ): void {
    if (this.unsortedMessages.length === 0) {
      return;
    }

    const messages = this.unsortedMessages.slice();
    const index = messages.findIndex((x) => x.id === changedMessage.id);
    if (index === -1) {
      return;
    }

    switch (changeType) {
      case 'added':
      case 'modified':
        const timeout = this.timeouts.get(changedMessage.id);
        if (timeout) {
          clearTimeout(timeout);
        } else if (deleted !== changedMessage.deleted) {
          messages.splice(index, 1);
          this.unsortedMessages = messages;
        } else {
          messages.splice(index, 1, changedMessage);
          this.unsortedMessages = messages;
        }
        break;
      case 'removed': {
        // serverTimestamp()で更新されるとremovedとaddedが連続で動くのでメッセージが消えないようにしている
        const id = setTimeout(() => {
          messages.splice(index, 1);
          this.unsortedMessages = messages;
          this.timeouts.delete(changedMessage.id);
        }, 1000);
        this.timeouts.set(changedMessage.id, id);
        break;
      }
      default:
        break;
    }
  }

  addHistory(entry: SearchHistoryEntry): boolean {
    const found = this._history.find((e) => isEqual(entry, e));
    if (found) {
      return false;
    }
    const updatedHistory = [entry, ...this._history].slice(0, 5);
    this._history = updatedHistory;
    lStorage.setObject(storageKeys.searchHistory, updatedHistory);
    return true;
  }

  private getHistoryFromStorage(): SearchHistoryEntry[] {
    return lStorage.getObject(storageKeys.searchHistory) ?? [];
  }
}
