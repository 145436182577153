import {
  LineContact,
  LineMessage as LineMessageEntity,
} from '../../../firestore/entity/line';
import { Avatar, Tooltip } from 'antd';
import { useStore } from 'hooks/useStore';
import React, { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import moment from 'moment';

type UserInfo = {
  name: string;
  src?: string;
  color?: string;
};

type Props = {
  contact?: LineContact;
  lineMessage: LineMessageEntity;
  setPreviewImageUrl: (url: string) => void;
  className?: string;
};

export const LineMessage = ({
  contact,
  lineMessage,
  setPreviewImageUrl,
  className = '',
}: Props): JSX.Element => {
  const [userInfo, setUserInfo] = useState<UserInfo>();

  const store = useStore();

  const getContent = () => {
    const messageData = lineMessage.data;
    switch (messageData.type) {
      case 'text':
      case 'yaritori':
        return messageData.text;
      case 'image':
        return (
          <img
            src={messageData.previewImageUrl}
            alt="（送信された画像）"
            onClick={() => setPreviewImageUrl(messageData.originalContentUrl)}
            className="max-h-[360px] max-w-[480px] cursor-pointer"
          />
        );
      case 'sticker':
        return '（スタンプが送信されました）';
    }
  };

  useEffect(() => {
    if (contact) {
      setUserInfo({
        name: contact.displayName,
        src: contact.pictureUrl ?? undefined,
      });
    } else if (lineMessage.source.type === 'yaritori') {
      const user = store.getUser(lineMessage.source.userId);
      setUserInfo({
        name: user?.name ?? '',
        src: user?.avatarURL,
        color: user?.iconBackgroundColor,
      });
    }
  }, [contact]);

  if (!userInfo) {
    return <></>;
  }

  const createdAt = moment(lineMessage.createdAt.toDate());
  const messageClass =
    lineMessage.source.type !== 'yaritori'
      ? 'border-l-2 border-sea-500 bg-sea-50'
      : lineMessage.source.internal
      ? 'border-l-2 border-wood-500 bg-wood-50'
      : '';

  const contentClass = messageClass ? 'bg-white' : 'bg-sumi-50';

  return (
    <div
      className={twMerge(
        className,
        'flex gap-2 rounded-l-sm rounded-r-lg p-2',
        messageClass
      )}
    >
      <div>
        <Avatar
          src={userInfo.src}
          size="large"
          style={{ backgroundColor: userInfo.src ? '#fff' : userInfo.color }}
        >
          {userInfo.name}
        </Avatar>
      </div>
      <div>
        <div className="mb-1 flex items-center gap-4">
          <div className="text-sm font-bold">{userInfo.name}</div>
          <div className="text-sm text-sumi-600">
            <Tooltip title={createdAt.format('YYYY年M月D日 HH:mm')}>
              <span>{createdAt.fromNow()}</span>
            </Tooltip>
          </div>
        </div>
        <div
          className={twMerge(
            'w-fit rounded-lg rounded-tl bg-sumi-50 px-3 py-2 text-sm',
            contentClass
          )}
        >
          {getContent()}
        </div>
      </div>
    </div>
  );
};
