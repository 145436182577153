import { Icon } from '../../../components/basics';
import { Star, StarFilled } from '../../../components/icons';
import { tv } from 'tailwind-variants';
import React from 'react';

type Props = {
  onClick: (e: React.MouseEvent) => void;
  starred?: boolean;
  disabled?: boolean;
};

const button = tv({
  base: 'm-0 h-4 w-4 cursor-pointer bg-transparent p-0 disabled:cursor-not-allowed disabled:opacity-50',
  variants: {
    starred: {
      true: 'text-sea-500',
      false: 'text-[#8d8d8d]',
    },
  },
});

export const StarButton = ({
  onClick,
  starred = false,
  disabled = false,
}: Props) => {
  return (
    <button
      aria-label="お気に入りボタン"
      type={'button'}
      onClick={onClick}
      className={button({ starred })}
      disabled={disabled}
    >
      <Icon icon={starred ? StarFilled : Star} size={18} />
    </button>
  );
};
