import { FromHoverCard } from '../FromHoverCard/FromHoverCard';
import * as HoverCard from '@radix-ui/react-hover-card';

type Props = {
  name: string;
  email: string;
  backgroundColor?: string;
  avatarUrl?: string;
  onOpenDetail: () => void;
};

export const FromName = ({
  name,
  email,
  backgroundColor,
  avatarUrl,
  onOpenDetail,
}: Props) => {
  return (
    <HoverCard.Root>
      <HoverCard.Trigger asChild>
        <button
          type="button"
          className="m-0 max-w-full cursor-pointer truncate bg-transparent p-0 text-sm"
          onClick={() => onOpenDetail()}
        >
          <span className="font-bold">{name}</span>
          <span className="ml-1 text-sumi-600">&lt;{email}&gt;</span>
        </button>
      </HoverCard.Trigger>
      <HoverCard.Content align="start" className="z-10">
        <FromHoverCard
          name={name}
          email={email}
          backgroundColor={backgroundColor}
          avatarUrl={avatarUrl}
          onOpenDetail={onOpenDetail}
        />
      </HoverCard.Content>
    </HoverCard.Root>
  );
};
