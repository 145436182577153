import {
  GroupedOption,
  Option,
  Select,
  SelectProps,
} from '../../../../components/forms';
import { SearchRange } from './useSearch';
import { useAtomValue } from 'jotai/index';
import { joinedTeamsAtom } from '../../../../atoms/firestore/team';
import { getFilterTargetTags } from './tag';
import { useStore } from '../../../../hooks/useStore';
import { observer } from 'mobx-react';
import { groupBy } from 'lodash';

type Props = Omit<SelectProps<string>, 'options'> & {
  range: SearchRange;
};

export const SearchTagSelect = observer(({ range, ...props }: Props) => {
  const store = useStore();
  const joinedTeams = useAtomValue(joinedTeamsAtom);
  const targetTags = getFilterTargetTags(range, store);
  const options: GroupedOption<string>[] = Object.entries(
    groupBy(targetTags, (tag) => tag.teamId)
  )
    .map(([teamId, tags]) => {
      const teamName = joinedTeams.find((t) => t.id === teamId)?.name ?? '';
      const tagOptions: Option<string>[] = tags
        .map((tag) => {
          const parentName = targetTags.find((t) => t.id === tag.parentTagId)
            ?.name;
          return {
            value: tag.id,
            label: parentName ? `${parentName}/${tag.name}` : tag.name,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label, 'ja'));
      return [teamName, tagOptions] as [string, Option<string>[]];
    })
    .map(([group, options]) => ({
      group,
      options,
      type: 'section',
    }));
  return <Select {...props} options={[{ value: '', label: '' }, ...options]} />;
});
