import { Timestamp } from 'firebase/firestore';
import { EventMessage, EventSource } from '@line/bot-sdk';
import { createConverter, createEntityFactory } from '../converter';

type LineMessageBase = {
  teamId: string;
  lineThreadId: string;
  lineContactId: string | null;
  source:
    | EventSource
    | {
        type: 'yaritori';
        userId: string;
        internal: boolean;
      };
  createdAt: Timestamp;
  updatedAt: Timestamp;
};

export type LineTextEvent = {
  type: 'text';
  text: string;
};

export type LineImageEvent = {
  type: 'image';
  originalContentUrl: string;
  previewImageUrl: string;
};

export type LineStickerEvent = {
  type: 'sticker';
};

export type LineEvent = LineTextEvent | LineImageEvent | LineStickerEvent;

export const toLineEvent = (message: EventMessage): LineEvent => {
  if (message.type === 'text') {
    return {
      type: message.type,
      text: message.text,
    };
  } else if (
    message.type === 'image' &&
    message.contentProvider.type === 'external'
  ) {
    const provider = message.contentProvider;
    return {
      type: message.type,
      originalContentUrl: provider.originalContentUrl,
      previewImageUrl: provider.previewImageUrl,
    };
  } else if (message.type === 'sticker') {
    return {
      type: message.type,
    };
  } else {
    throw new Error('unsupported message type');
  }
};

export type LineYaritoriMessage = {
  type: 'yaritori';
  text: string;
} & LineMessageBase;

export type LineTextMessage = LineTextEvent & LineMessageBase;

export type LineImageMessage = LineImageEvent & LineMessageBase;

export type LineStickerMessage = LineStickerEvent & LineMessageBase;

export type LineMessageData =
  | LineYaritoriMessage
  | LineTextMessage
  | LineImageMessage
  | LineStickerMessage;

export const createLineMessage = createEntityFactory<
  'LineMessage',
  LineMessageData
>('LineMessage');

export type LineMessage = ReturnType<typeof createLineMessage>;

export const lineMessageConverter = createConverter(createLineMessage);

export const LineThreadStatus = {
  Unprocessed: 'unprocessed',
  Processed: 'processed',
} as const;

export type LineThreadStatus =
  (typeof LineThreadStatus)[keyof typeof LineThreadStatus];

export type LineThreadSource = {
  type: EventSource['type'];
  id: string;
};

export const toThreadSource = (source: EventSource) => {
  return {
    type: source.type,
    id:
      source.type === 'user'
        ? source.userId
        : source.type === 'group'
        ? source.groupId
        : source.roomId,
  };
};

export type LineThreadData = {
  teamId: string;
  lineAccountId: string;
  source: LineThreadSource;
  status: LineThreadStatus;
  lineContacts: string[];
  lineMessages: string[];
  lastLineContact: Omit<LineContactData, 'teamId' | 'createdAt' | 'updatedAt'>;
  lastLineEvent: LineEvent;
  readers: string[];
  lastReceivedAt: Timestamp;
  createdAt: Timestamp;
  updatedAt: Timestamp;
};

export const createLineThread = createEntityFactory<
  'LineThread',
  LineThreadData
>('LineThread');

export type LineThread = ReturnType<typeof createLineThread>;

export const lineThreadConverter = createConverter(createLineThread);

export type LineAccountData = {
  teamId: string;
  name: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
};

export const createLineAccount = createEntityFactory<
  'LineAccount',
  LineAccountData
>('LineAccount');

export type LineAccount = ReturnType<typeof createLineAccount>;

export const lineAccountConverter = createConverter(createLineAccount);

export type LineContactData = {
  teamId: string;
  userId: string;
  displayName: string;
  pictureUrl: string | null;
  createdAt: Timestamp;
  updatedAt: Timestamp;
};

export const createLineContact = createEntityFactory<
  'LineContact',
  LineContactData
>('LineContact');

export type LineContact = ReturnType<typeof createLineContact>;

export const lineContactConverter = createConverter(createLineContact);

export type LineIntegrationToken = {
  accessToken: string;
  expiresAt: Timestamp;
};

export type LineIntegrationData = {
  companyId: string;
  teamId: string;
  lineAccountId: string;
  channelId: string;
  channelSecret: string;
  token: LineIntegrationToken;
  createdAt: Timestamp;
  updatedAt: Timestamp;
};
