import { decorateHtml, Message, messageConverter, StorageMessage } from 'lib';
import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import LogoImageSrc from '../../../assets/logos/logo_wide_fullcolor_RGB.png';
import { fetchAsJson, fetchAsText } from '../../../util';
import { PrintMessageBody } from './PrintMessageBody';
import Header from './header';
import { companyDoc } from '../../../firestore';
import { getDoc } from 'firebase/firestore';
import { Loading } from 'components/basics';
import { useAtomValue } from 'jotai';
import { meAtom } from 'atoms/auth';
import { FirebaseError } from 'firebase/app';

export const PrintMessagePath = '/print/messages/:messageId';

export const PrintMessage = () => {
  const match = useRouteMatch<{ messageId: string }>();
  const me = useAtomValue(meAtom);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState<Message | null>(null);
  const [html, setHTML] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const loadMessage = async (messageId: string) => {
    try {
      const message = (
        await getDoc(companyDoc('messages', messageId, messageConverter))
      ).data();

      if (!message) {
        console.error('Message Not Found');
        setErrorMessage('該当のメールが存在しません。');
        return;
      }

      setMessage(message);
      setHTML(await fetchMessageFromStorage(message));
      setLoading(false);

      setTimeout(() => {
        window.print();
      }, 500);
    } catch (err: unknown) {
      if (err instanceof FirebaseError && err.code === 'permission-denied') {
        setErrorMessage('アクセスの権限がありません。');
      } else {
        setErrorMessage('予期せぬエラーが発生しました。');
      }
      console.error(err);
    }
  };

  const fetchMessageFromStorage = async (message: Message): Promise<string> => {
    if (message.html) {
      return message.html;
    }

    if (message.htmlStoragePath) {
      const storageHtml = await fetchAsText(message.htmlStoragePath);
      return decorateHtml(storageHtml);
    }

    if (message.textAsHtml) {
      return message.textAsHtml;
    }

    if (message.textAsHtmlStoragePath) {
      const storageTextAsHtml = await fetchAsText(
        message.textAsHtmlStoragePath
      );
      return decorateHtml(storageTextAsHtml);
    }

    // 古いメールにのみ存在
    if (message.storagePath) {
      const storageMessage = await new StorageMessage(
        message.id,
        await fetchAsJson(message.storagePath)
      );

      if (storageMessage) {
        return storageMessage.html
          ? storageMessage.html
          : storageMessage.sanitizedTextAsHtml;
      }

      return '';
    }

    return '';
  };

  useEffect(() => {
    setLoading(true);
    loadMessage(match.params.messageId);
  }, [match.params.messageId]);

  if (loading) {
    return <Loading />;
  }

  if (errorMessage) {
    return <div>{errorMessage}</div>;
  }

  return (
    <div className="m-[8px] w-full bg-white">
      {message && (
        <>
          <div className="flex items-center justify-between pb-[12px] pt-[12px]">
            <div className="flex items-center">
              <img alt="" className="h-[40px]" src={LogoImageSrc} />
            </div>
            <p className="font-bold text-gray-500">{`${me.name} <${me.email}>`}</p>
          </div>
          <hr />
          <Header>{message.subject}</Header>
          <hr />
          <PrintMessageBody
            message={message}
            html={html}
            loading={loading}
            attachments={message.attachments}
          />
        </>
      )}
    </div>
  );
};
