import { Store } from '../../../../store';
import { Options } from '../../../../components/forms';
import { User } from 'lib';
import { SearchRange } from './useSearch';

export const getFilterTargetMembers = (
  range: SearchRange,
  store: Store
): User[] => {
  const teamIds =
    range.type === 'allInboxes' ? store.joinedTeamIds : [range.teamId];
  const ids = teamIds ?? store.joinedTeamIds;
  const teams = store.joinedTeams.filter((team) => ids.includes(team.id));
  return store.users.filter((user) =>
    teams.some((team) => team.isMember(user.id))
  );
};

export const getFilterMemberOptions = (
  range: SearchRange,
  store: Store
): Options<string | null> => {
  const members: Options<string> = getFilterTargetMembers(range, store)
    .filter((user) => user.id !== store.me.id)
    .map((user) => ({ value: user.id, label: user.name }));
  return [
    { value: '', label: '' },
    { value: null, label: 'なし' },
    { value: store.me.id, label: '自分' },
    ...members,
  ];
};
