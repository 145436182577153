import {
  GroupedOption,
  Option,
  Options,
  Select,
  SelectProps,
} from '../../../../components/forms';
import { MessageStatus } from 'lib';
import { SearchRange } from './useSearch';
import { useMemo } from 'react';
import { getFilterCustomStatuses } from './customStatuses';
import { useAtomValue } from 'jotai/index';
import { allTeamStatusesInGroupAtom } from '../../../../atoms/firestore/customStatuses';
import { joinedTeamsAtom } from '../../../../atoms/firestore/team';

type Props = Omit<SelectProps<string>, 'options'> & {
  range: SearchRange;
};

export const SearchStatusSelect = ({ range, ...props }: Props) => {
  const [, allCustomStatuses] = useAtomValue(allTeamStatusesInGroupAtom);
  const joinedTeams = useAtomValue(joinedTeamsAtom);
  const customStatusOptions: Options<string> = useMemo(() => {
    return Object.keys(allCustomStatuses)
      .map((teamId) => {
        const teamName = joinedTeams.find((t) => t.id === teamId)?.name ?? '';
        const statuses = getFilterCustomStatuses(range, allCustomStatuses);
        const options: Options<string> = statuses
          .filter((s) => allCustomStatuses[teamId].includes(s))
          .map((s) => ({
            label: s.name,
            value: s.id,
          }));
        return [teamName, options] as [string, Option<string>[]];
      })
      .filter(([, options]) => options.length > 0)
      .map(
        ([group, options]): GroupedOption<string> => ({
          group,
          options,
          type: 'section',
        })
      )
      .sort((a, b) => a.group.localeCompare(b.group, 'ja'));
  }, [range, allCustomStatuses]);
  return (
    <Select
      {...props}
      options={[
        MessageStatus.None,
        MessageStatus.Unprocessed,
        MessageStatus.Processed,
        ...customStatusOptions,
      ]}
    />
  );
};
