import { DeliveryMessageFormData } from './DeliveryMessageCreateForm';
import { DeliveryMessagePreviewDialog } from '../../list/DeliveryMessagePreviewDialog/DeliveryMessagePreviewDialog';
import { useAtomValue } from 'jotai/index';
import { deliveryAddressesAtom } from '../../../../atoms/firestore/deliveryAddress';
import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../../hooks/useStore';
import { teamsAtom } from '../../../../atoms/firestore/team';
import { sendBulkMessagesFunction } from '../../../../functions';
import { companyAtom } from '../../../../atoms/auth';
import { useToast } from '../../../../hooks/useToast';
import { useHistory } from 'react-router-dom';
import { DELIVERY_PATHS } from '../../page/DeliveryPage/deliveryPaths';
import { FunctionsError } from '@firebase/functions';
import { FirebaseError } from 'firebase/app';
import { useConfirmDialog } from '../../../../hooks/confirmDialog';

type Props = {
  messageId: string;
  data: DeliveryMessageFormData;
  recipients: {
    count: number;
    total: number;
  };
  onClose: () => void;
};

type InvalidEmailsError = Omit<FunctionsError, 'details'> & {
  details: {
    invalidEmails: string[];
  };
};

export const DeliverySendConfirmDialog = observer(
  ({ messageId, data, recipients, onClose }: Props) => {
    const store = useStore();
    const company = useAtomValue(companyAtom);
    const deliveryAddresses = useAtomValue(deliveryAddressesAtom);
    const teams = useAtomValue(teamsAtom);
    const { showToast } = useToast();
    const history = useHistory();
    const confirm = useConfirmDialog();
    const address = useMemo(() => {
      const found = deliveryAddresses.find((a) => a.id === data.from);
      if (!found) {
        return { name: null, email: '' };
      }
      return {
        name: found.name ?? null,
        email: found.email,
      };
    }, [deliveryAddresses, data]);
    const tags = teams
      .flatMap((team) => {
        const tags = store.getContactTags(team.id);
        return tags.map((tag) => ({
          teamId: team.id,
          teamName: team.name,
          id: tag.id,
          name: tag.name,
          color: tag.color,
        }));
      })
      .filter((tag) => data.tags.includes(tag.id))
      .map((tag) => ({
        team: tag.teamName,
        name: tag.name,
        color: tag.color ?? null,
      }));
    const showInvalidEmailsErrorDialog = (emails: string[]) => {
      confirm({
        title: '送信できませんでした',
        description: (
          <div className="flex flex-col gap-2">
            <div>
              宛先に不正なメールアドレスが含まれているため、送信できませんでした。
              <br />
              修正して再度お試しください。
            </div>
            <ul className="m-0 list-disc pl-6">
              {emails.map((email, i) => (
                <li key={i}>{email}</li>
              ))}
            </ul>
          </div>
        ),
      });
    };
    const onSend = async () => {
      try {
        await sendBulkMessagesFunction({
          companyId: company.id,
          deliveryMessageId: messageId,
        });
        showToast('success', '送信しました');
        history.push(DELIVERY_PATHS.getIndexPath());
      } catch (e) {
        if (isInvalidEmailsError(e)) {
          showInvalidEmailsErrorDialog(e.details.invalidEmails);
          return;
        }
        console.error(e);
        showToast('error', '送信できませんでした');
      }
    };
    return (
      <DeliveryMessagePreviewDialog
        open={true}
        onOpenChange={(open) => {
          if (!open) {
            onClose();
          }
        }}
        onSend={onSend}
        address={address}
        tags={tags}
        subject={data.subject}
        html={data.body ?? undefined}
        text={data.bodyText}
        attachments={data.attachments}
        recipients={recipients}
      />
    );
  }
);

const isInvalidEmailsError = (err: unknown): err is InvalidEmailsError => {
  if (!(err instanceof FirebaseError)) {
    return false;
  }
  if (err.message !== 'Found invalid email addresses') {
    return false;
  }
  const details = (err as { details?: { invalidEmails?: string[] } }).details;
  return typeof details === 'object' && Array.isArray(details.invalidEmails);
};
