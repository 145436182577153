import { Route, Switch } from 'react-router-dom';
import { PrintMessage, PrintMessagePath } from './Message/PrintMessage';
import Sent from './Sent';

export const AppPrintIndex = () => {
  return (
    <div className="flex h-[100vh]">
      <Switch>
        {/* メッセージ */}
        <Route path={PrintMessagePath} component={PrintMessage} />
        {/* 送信済み */}
        <Route path="/print/sent/:sentId" component={Sent} />
      </Switch>
    </div>
  );
};
