import { SearchQuery } from '../../../store/search';
import { Store } from '../../../store';

/**
 * 検索のクエリから検索結果などに使うテキストを作る
 */
export const getFilterText = (store: Store, query: SearchQuery): string => {
  const {
    keywords,
    teamIds,
    inboxId,
    status,
    from,
    to,
    subjectOrText,
    tags: tagIds,
    assignee,
    after,
    before,
    hasAttachments,
    attachmentsFilename,
  } = query;
  const keywordFilter = keywords?.join(' ') ?? '';

  const teams = teamIds
    ? store.joinedTeams.filter((team) => teamIds.includes(team.id))
    : undefined;
  const teamFilter = addPrefix(
    'チーム',
    teams?.map((team) => team.name).join(', ')
  );

  const inbox = inboxId ? store.getInbox(inboxId) : undefined;
  const inboxFilter = addPrefix('トレイ', inbox?.yaritoriName || inbox?.email);

  const statusFilter = addPrefix('ステータス', status);
  const fromFilter = addPrefix('From', from);
  const toFilter = addPrefix('To', to);
  const subjectOrTextFilter = addPrefix('件名,本文', subjectOrText);

  const tags = tagIds
    ? store.tags.filter((tag) => tagIds.includes(tag.id))
    : undefined;
  const tagFilter = addPrefix('タグ', tags?.map((tag) => tag.name).join(', '));

  let assignedUserName: string | undefined;
  if (assignee != null) {
    assignedUserName = store.getUser(assignee)?.name;
  } else if (assignee === null) {
    assignedUserName = 'なし';
  } else {
    assignedUserName = undefined;
  }
  const assigneeFilter = addPrefix('担当者', assignedUserName);

  let dateFilter = '';
  if (after || before) {
    dateFilter = addPrefix('受信日時', (after ?? '') + ' ~ ' + (before ?? ''));
  }

  const hasAttachmentsFilter = addPrefix(
    '添付',
    hasAttachments ? 'あり' : undefined
  );
  const attachmentsFilenameFilter = addPrefix(
    '添付ファイル名',
    attachmentsFilename
  );

  const filterTexts = [
    keywordFilter,
    teamFilter,
    inboxFilter,
    statusFilter,
    fromFilter,
    toFilter,
    subjectOrTextFilter,
    tagFilter,
    assigneeFilter,
    dateFilter,
    hasAttachmentsFilter,
    attachmentsFilenameFilter,
  ].filter(Boolean);

  return filterTexts.length > 0 ? filterTexts.join(', ') : 'なし';
};

const addPrefix = (prefix: string, value?: string) => {
  return value ? prefix + '：' + value : '';
};
