import { Controller } from 'react-hook-form';
import {
  Checkbox,
  DatePicker,
  Input,
  Label,
  Select,
} from '../../../components/forms';
import { memo, PropsWithChildren, useEffect } from 'react';
import { Button } from '../../../components/basics';
import { trayOptions, useSearch } from './search/useSearch';
import { SearchRangeSelect } from './search/SearchRangeSelect';
import { SearchStatusSelect } from './search/SearchStatusSelect';
import { SearchTagSelect } from './search/SearchTagSelect';

type Props = {
  search: ReturnType<typeof useSearch>;
};

export const SearchFilterForm = memo(({ search }: Props): JSX.Element => {
  const { setValue, watch, handleSubmit, register, control, reset } =
    search.filterForm;

  const tray = watch('tray');

  const hasAttachments = watch('hasAttachments');
  useEffect(() => {
    if (!hasAttachments) {
      setValue('attachmentsFilename', '');
    }
  }, [hasAttachments]);

  return (
    <form onSubmit={handleSubmit(search.search)}>
      <div className="flex flex-col gap-4 p-4">
        <LabeledInput id="tray" label="種類">
          <Controller
            control={control}
            render={({ field: { ref, ...field } }) => (
              <Select
                id="tray"
                {...field}
                options={trayOptions}
                variants={{ width: 'full', rounded: 'lg' }}
              />
            )}
            name="tray"
          />
        </LabeledInput>
        <LabeledInput id="searchRange" label="アドレス">
          <Controller
            control={control}
            render={({ field: { ref, ...field } }) => (
              <SearchRangeSelect
                id="searchRange"
                {...field}
                variants={{ width: 'full', rounded: 'lg' }}
              />
            )}
            name="searchRange"
          />
        </LabeledInput>
        {tray !== 'sent' && (
          <LabeledInput id="status" label="ステータス">
            <Controller
              control={control}
              render={({ field }) => (
                <SearchStatusSelect
                  id="status"
                  {...field}
                  range={watch('searchRange')}
                  placeholder="選択してください"
                  variants={{ width: 'full', rounded: 'lg' }}
                />
              )}
              name="status"
            />
          </LabeledInput>
        )}
        <LabeledInput id="from" label="From">
          <Input
            id="from"
            {...register('from')}
            placeholder="入力してください"
            size="sm"
          />
        </LabeledInput>
        <LabeledInput id="to" label="To">
          <Input
            id="to"
            {...register('to')}
            placeholder="入力してください"
            size="sm"
          />
        </LabeledInput>
        <LabeledInput id="subjectOrText" label="件名,本文">
          <Input
            id="subjectOrText"
            {...register('subjectOrText')}
            placeholder="入力してください"
            size="sm"
          />
        </LabeledInput>
        {tray !== 'sent' && (
          <>
            <LabeledInput id="tag" label="タグ">
              <Controller
                control={control}
                render={({ field }) => (
                  <SearchTagSelect
                    id="tag"
                    range={watch('searchRange')}
                    {...field}
                    placeholder="選択してください"
                    variants={{ width: 'full', rounded: 'lg' }}
                  />
                )}
                name="tag"
              />
            </LabeledInput>
            <LabeledInput id="assignee" label="担当者">
              <Controller
                control={control}
                render={({ field }) => (
                  <Select
                    id="assignee"
                    {...field}
                    options={search.memberOptions}
                    placeholder="選択してください"
                    variants={{ width: 'full', rounded: 'lg' }}
                  />
                )}
                name="assignee"
              />
            </LabeledInput>
          </>
        )}

        <LabeledInput id="after" label="受信日時">
          <div className="flex gap-2.5">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <DatePicker
                  id="after"
                  mode="single"
                  onSelect={(date) => onChange(date)}
                  selected={value ?? undefined}
                  onDayBlur={onBlur}
                  ref={ref}
                  toDate={watch('before') ?? undefined}
                  placement="bottomLeft"
                  closeWhenSelected
                />
              )}
              name="after"
            />
            <div className="flex select-none items-center justify-center text-lg text-sumi-500">
              ~
            </div>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <DatePicker
                  mode="single"
                  onSelect={(date) => onChange(date)}
                  selected={value ?? undefined}
                  onDayBlur={onBlur}
                  ref={ref}
                  fromDate={watch('after') ?? undefined}
                  placement="bottomLeft"
                  closeWhenSelected
                />
              )}
              name="before"
            />
          </div>
        </LabeledInput>
        {tray !== 'sent' && (
          <Checkbox label="添付ファイルあり" {...register('hasAttachments')} />
        )}
        {watch('hasAttachments') && (
          <LabeledInput id="attachmentsFilename" label="添付ファイル名">
            <Input
              id="attachmentsFilename"
              size="sm"
              {...register('attachmentsFilename')}
            />
          </LabeledInput>
        )}
        <div className="flex justify-end gap-2.5 text-sm">
          <Button
            color="primary"
            variant="outlined"
            type="button"
            onClick={() => reset()}
            className="h-8 w-[72px] p-0 sm:p-0"
          >
            リセット
          </Button>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            className="h-8 w-[72px] rounded p-0"
          >
            検索
          </Button>
        </div>
      </div>
    </form>
  );
});

type LabeledInputProps = {
  id: string;
  label: string;
};

const LabeledInput = ({
  id,
  label,
  children,
}: PropsWithChildren<LabeledInputProps>) => {
  return (
    <Label htmlFor={id} label={label} className="w-1/6">
      {children}
    </Label>
  );
};
