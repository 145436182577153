import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../../../hooks/useStore';
import { useHistory } from 'react-router-dom';
import { Sent as SentEntity } from 'lib';
import { useAtom } from 'jotai';
import { sentAtom } from '../../../../../atoms/firestore/sent';
import { WrappedInfiniteScroll } from '../../../../../components/WrappedInfiniteScroll';
import { Icon, Spin } from 'antd';
import SentCheckOptions from './sentCheckOptions';
import SentItem from './SentItem';
import { SearchMessage } from '../../../../../store/search';

type Props = {
  generateLink: (sent: SentEntity | SearchMessage) => string;
};

export const Sent = observer(({ generateLink }: Props): JSX.Element => {
  const store = useStore();
  const history = useHistory();

  const { searchStore } = store;
  const {
    searching: searchingSent,
    hasMore: hasMoreSentOnSearch,
    sortedSent,
    inSearch,
  } = store.searchStore;

  const [sentPaginate, dispatch] = useAtom(sentAtom);

  const searchedSentList = inSearch
    ? sortedSent
    : sentPaginate.state === 'hasData'
    ? sentPaginate.data
    : [];
  const loadableMore =
    (!searchingSent &&
      inSearch &&
      hasMoreSentOnSearch &&
      searchedSentList.length > 0) ||
    sentPaginate.hasMore;

  useEffect(() => {
    return () => {
      store.checkedSent = [];
    };
  }, []);

  const to = (link: string) => history.push(link);

  const loadMore = async () => {
    if (inSearch) {
      await loadMoreOnSearch();
    } else {
      await loadMoreOnList();
    }
  };

  const loadMoreOnSearch = async () => {
    if (hasMoreSentOnSearch) {
      await searchStore.searchSent({ withOffset: true });
    }
  };

  const loadMoreOnList = async () => {
    await dispatch('loadMore');
  };

  const loading = inSearch ? searchingSent : sentPaginate.state === 'loading';

  return (
    <WrappedInfiniteScroll
      initialLoad={false}
      pageStart={0}
      loadMore={loadMore}
      hasMore={loadableMore}
      useWindow={false}
    >
      <Spin spinning={loading} indicator={<Icon type="loading" spin />}>
        <div className="sticky top-0 z-10 bg-sumi-50 pt-4">
          {store.checkedSent.length > 0 && (
            <SentCheckOptions searchedSentList={searchedSentList} />
          )}
        </div>
        {searchedSentList.map((sent) => (
          <SentItem
            item={sent}
            toFunc={to}
            checkSent={store.checkSent}
            uncheckSent={store.uncheckSent}
            checkedSent={store.checkedSent}
            to={generateLink(sent)}
            key={sent.id}
          />
        ))}
      </Spin>
    </WrappedInfiniteScroll>
  );
});
