import { observer } from 'mobx-react';
import { useContactNameByEmail } from '../../../MessageList/Teams/Messages/Message/contact';
import { FromName } from '../FromName/FromName';
import { useCallback } from 'react';
import { eventNames, logEvent } from '../../../../../analytics';
import { useStore } from '../../../../../hooks/useStore';

type Props = {
  email: { address: string; name?: string };
  teamId: string;
};

export const HeaderFromName = observer(({ email, teamId }: Props) => {
  const store = useStore();
  const { name, loading } = useContactNameByEmail({ email, teamId });
  const user = store.getUserByEmail(email.address);

  const onOpenDetail = useCallback(() => {
    store.contactStore.selectContact(teamId, email).then();
    logEvent(eventNames.show_message_histories);
  }, [email, teamId]);

  if (loading) {
    return (
      <div className="flex h-[20px] w-full items-center">
        <div className="h-4 w-full animate-pulse rounded bg-sumi-100" />
      </div>
    );
  }

  return (
    <FromName
      name={name || user?.iconName || ''}
      backgroundColor={user?.iconBackgroundColor}
      avatarUrl={user?.avatarURL}
      email={email.address}
      onOpenDetail={onOpenDetail}
    />
  );
});
