import { SearchRange } from './useSearch';
import { CustomStatusesData } from '../../../../atoms/firestore/customStatuses';
import { MessageStatus } from 'lib';

export const getFilterCustomStatuses = (
  range: SearchRange,
  customStatuses: CustomStatusesData
) => {
  const statuses =
    range.type === 'allInboxes'
      ? Object.values(customStatuses).flat()
      : customStatuses[range.teamId];

  return statuses.filter((status) => status.name !== MessageStatus.Unprocessed);
};
