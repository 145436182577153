import { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import * as color from '../../../../../color';
import CreateMessage from '../../../CreateMessage';
import { Draft as DraftEntity } from '../../../../../firestore/entity/draft';
import { getScrollContainerId } from '../../util';
import { useAtomValue } from 'jotai';
import { modalDraftAtom } from '../../../../../atoms/firestore/draft';

type Props = {
  draft: DraftEntity;
};

export const Draft = ({ draft }: Props): JSX.Element => {
  const history = useHistory();
  const frameRef = useRef<HTMLDivElement>(null);
  const modalDraft = useAtomValue(modalDraftAtom);

  const onEnter = () => {
    const container = document.querySelector(`#${getScrollContainerId()}`);
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  };

  const deleteCallback = () => {
    if (!draft.isReply && !draft.inReplyToSentId) history.replace('/me/drafts');
  };

  const editingInModal = modalDraft && modalDraft.id === draft.id;

  return (
    <div
      ref={frameRef}
      className={`mt-[16px] grid rounded-xl p-[16px] shadow-card max-sm:min-h-[calc(100vh_-_69px)] sm:m-[16px] sm:min-h-[400px] sm:rounded-[10px]`}
      style={{
        backgroundColor: color.common.white,
      }}
    >
      {editingInModal ? (
        <div>
          別ウィンドウで編集中です
          <span role="img" aria-label="memo">
            📝
          </span>
        </div>
      ) : (
        /* 返信 */
        <CreateMessage
          draft={draft}
          onEnter={onEnter}
          deleteCallback={deleteCallback}
        />
      )}
    </div>
  );
};
