import { Redirect, useParams } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import {
  arrayUnion,
  documentId,
  limit,
  orderBy,
  query,
  serverTimestamp,
  startAt,
  updateDoc,
  where,
} from 'firebase/firestore';
import {
  fetchQueryInArray,
  subscribeQueryInArray,
} from '../../../utils/firebase';
import { Icon, Spin } from 'antd';
import { LineConversationHeader } from './LineConversationHeader';
import { SendMessage } from './SendMessage';
import { isSP } from '../../../shared/util';
import { tv } from 'tailwind-variants';
import { downloadFromUrl } from '../../../util';
import PreviewImageModal from '../../Common/Modal/previewImageModal';
import {
  useSubscribeCollection,
  useSubscribeDocument,
} from '../../../hooks/firestore/subscription';
import {
  companyCollection,
  companyDoc,
  registerUnsubscribe,
} from '../../../firestore';
import {
  lineAccountConverter,
  LineContact,
  lineContactConverter,
  LineMessage as LineMessageEntity,
  lineMessageConverter,
  lineThreadConverter,
  LineThreadStatus,
} from '../../../firestore/entity/line';
import { useAtomValue } from 'jotai';
import { joinedTeamIdsAtom } from '../../../atoms/firestore/team';
import { meAtom } from '../../../atoms/auth';
import { v4 } from 'uuid';
import { LineMessage } from './LineMessage';
import { sortBy } from 'lodash';
import { InfiniteScroll } from 'components/basics/InfiniteScroll';

const container = tv({
  base: 'flex max-h-full max-w-full flex-col px-4 pb-4',
  variants: {
    sp: {
      true: 'h-[100vh]',
      false: 'h-full',
    },
  },
});

const InternalLineConversation = (): JSX.Element => {
  const [lineContacts, setLineContacts] = useState<LineContact[]>([]);
  const [lineThreadsLimit, setLineThreadsLimit] = useState(1);
  const [lineMessagesLoading, setLineMessagesLoading] = useState(true);
  const [lineMessages, setLineMessages] = useState<LineMessageEntity[]>([]);
  const [previewImageUrl, setPreviewImageUrl] = useState<string | null>(null);
  const me = useAtomValue(meAtom);
  const joinedTeamIds = useAtomValue(joinedTeamIdsAtom);
  const containerRef = useRef<HTMLDivElement>(null);

  const { teamId, lineAccountId, tab, lineThreadId } = useParams<{
    teamId: string;
    lineAccountId: string;
    tab: string;
    lineThreadId: string;
  }>();

  const [lineAccountLoading, lineAccount] = useSubscribeDocument(
    companyDoc('lineAccounts', lineAccountId, lineAccountConverter)
  );
  const [rootThreadLoading, rootLineThread] = useSubscribeDocument(
    companyDoc('lineThreads', lineThreadId, lineThreadConverter)
  );

  const [, lineThreads] = useSubscribeCollection(() => {
    if (!rootLineThread) {
      return undefined;
    }
    return query(
      companyCollection('lineThreads', lineThreadConverter),
      where('teamId', 'in', joinedTeamIds),
      where('source.type', '==', rootLineThread.source.type),
      where('source.id', '==', rootLineThread.source.id),
      limit(lineThreadsLimit),
      orderBy('createdAt', 'desc'),
      startAt(rootLineThread.createdAt)
    );
  }, [joinedTeamIds, rootLineThread, lineThreadsLimit]);

  useEffect(() => {
    if (rootLineThread) {
      fetchQueryInArray(
        query(companyCollection('lineContacts', lineContactConverter)),
        documentId(),
        rootLineThread.lineContacts
      ).then((docs) => setLineContacts(docs.map((doc) => doc.data())));
      if (!rootLineThread.readers.includes(me.id)) {
        updateDoc(rootLineThread.ref, {
          readers: arrayUnion(me.id),
          updatedAt: serverTimestamp(),
        });
      }
    }
  }, [rootLineThread]);

  useEffect(() => {
    setLineMessagesLoading(true);
    return registerUnsubscribe(
      v4(),
      subscribeQueryInArray(
        query(
          companyCollection('lineMessages', lineMessageConverter),
          where('teamId', 'in', joinedTeamIds)
        ),
        'lineThreadId',
        lineThreads.map((t) => t.id),
        (snap, docs) => {
          const messages = sortBy(
            docs.map((doc) => doc.data()),
            'createdAt'
          );
          setLineMessages(messages);
        },
        () => {
          setLineMessagesLoading(false);
        }
      )
    );
  }, [joinedTeamIds, lineThreads]);

  const hasMore = lineThreads.length === lineThreadsLimit;

  const getContact = (message: LineMessageEntity) => {
    return lineContacts.find((c) => c.id === message.lineContactId);
  };

  const loading =
    lineAccountLoading || rootThreadLoading || !lineContacts.length;
  if (loading) {
    return (
      <div className="flex h-full flex-1 items-center justify-center">
        <Spin indicator={<Icon type="loading" spin />} />
      </div>
    );
  }

  if (!lineAccount || !rootLineThread) {
    return (
      <Redirect to={`/teams/${teamId}/lineaccounts/${lineAccountId}/${tab}`} />
    );
  }

  const loadMore = () => {
    setLineThreadsLimit((prev) => prev + 1);
  };

  return (
    <div className={container({ sp: isSP() })}>
      <LineConversationHeader
        lineAccount={lineAccount}
        lineThread={rootLineThread}
        lineContacts={lineContacts}
      />
      <div className="flex flex-1 flex-col rounded-xl bg-white px-4">
        <div className="relative flex-1">
          <div
            className="absolute flex h-full w-full flex-col-reverse overflow-auto pt-4"
            ref={containerRef}
          >
            <div>
              <InfiniteScroll
                hasMore={hasMore}
                isLoading={lineMessagesLoading}
                next={loadMore}
                reverse={true}
                rootMargin="250px 0px 0px 0px"
                root={containerRef.current}
              >
                <div />
              </InfiniteScroll>
              <div className="flex flex-col gap-2">
                {lineMessages.map((message) => (
                  <LineMessage
                    key={message.id}
                    lineMessage={message}
                    contact={getContact(message)}
                    setPreviewImageUrl={setPreviewImageUrl}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="pb-4 pt-2">
          <SendMessage
            user={me}
            lineThread={rootLineThread}
            setPreviewImageUrl={setPreviewImageUrl}
            disabled={
              rootLineThread.status === LineThreadStatus.Processed ||
              me.isReadOnly
            }
            onSend={() => {
              const scrollElement = containerRef.current;
              scrollElement?.scrollTo({
                top: scrollElement?.scrollHeight,
                behavior: 'smooth',
              });
            }}
          />
        </div>
      </div>
      <PreviewImageModal
        open={previewImageUrl}
        onClose={() => setPreviewImageUrl(null)}
        url={previewImageUrl}
        onDownload={() => downloadFromUrl(previewImageUrl)}
      />
    </div>
  );
};

export const LineConversation = () => {
  const { lineThreadId } = useParams<{ lineThreadId: string }>();
  return <InternalLineConversation key={lineThreadId} />;
};
