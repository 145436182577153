import { Button, Icon, Modal, notification } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { FieldValue } from '../../../firebase';
import { createMessage, MessageStatus } from 'lib';
import { eventNames, logEvent } from '../../../analytics';
import mobxStore from '../../../store';
import { sendMessageFunction } from '../../../functions';
import { WriteBatch } from '../../../utils/firestore';
import { addDoc, getDoc, serverTimestamp, updateDoc } from 'firebase/firestore';
import {
  SendMessageError,
  SendMessageErrorContent,
} from './SendMessageErrorContent';
import {
  buildFrontSystemMessageStatus,
  FrontMessageStatus,
  frontMessageStatusToFirestore,
  UnknownFrontMessageStatus,
} from 'utils/frontMessageStatus';
import { companyCollection, companyDoc } from '../../../firestore';
import {
  Draft,
  draftConverter,
  DraftData,
} from '../../../firestore/entity/draft';
import { store } from '../../../providers/StoreProvider';
import { modalDraftAtom } from '../../../atoms/firestore/draft';

const openNotification = (key: string) => {
  notification.open({
    icon: <Icon type="loading" />,
    className: 'yaritori-notification-send-message',
    message: 'メッセージを送信中です…',
    placement: 'bottomLeft',
    closeIcon: <div />,
    key,
    duration: 0, // 閉じない
  });
};

const updateCancellableNotification = (key: string, cancel: () => void) => {
  notification.open({
    icon: <Icon type="loading" />,
    className: 'yaritori-notification-send-message',
    message: 'メッセージを送信中です…',
    placement: 'bottomLeft',
    closeIcon: <div />,
    btn: (
      <Button
        type="link"
        onClick={() => {
          cancel();
          notification.close(key);
        }}
      >
        キャンセル
      </Button>
    ),
    key,
    duration: 0, // 閉じない
  });
};

const updateSendingNotification = (key: string) => {
  notification.open({
    icon: <Icon type="loading" />,
    className: 'yaritori-notification-send-message',
    message: 'メッセージを送信中です…',
    placement: 'bottomLeft',
    closeIcon: <div />,
    btn: (
      <span
        className="yaritori-notification-send-message-close-wrapper"
        onClick={() => notification.close(key)}
      >
        <CloseOutlined />
      </span>
    ),
    key,
    duration: 0, // 閉じない
  });
};

const updateSuccessNotification = (key: string) => {
  notification.success({
    className: 'yaritori-notification-send-message',
    message: 'メッセージを送信しました',
    placement: 'bottomLeft',
    closeIcon: <div />,
    btn: (
      <span
        className="yaritori-notification-send-message-close-wrapper"
        onClick={() => notification.close(key)}
      >
        <CloseOutlined />
      </span>
    ),
    key,
    duration: 1,
  });
};

const sleep = (msec: number) =>
  new Promise((resolve) => setTimeout(resolve, msec));

export const sendMessage = (
  draftId: string,
  status: FrontMessageStatus | UnknownFrontMessageStatus | null
) => {
  return new Promise(async (resolve) => {
    let isCanceled = false;
    mobxStore.cancelableSendingMessageIds = [
      ...mobxStore.cancelableSendingMessageIds,
      draftId,
    ];

    const modalDraft = store.get(modalDraftAtom);
    // 送信対象がモーダルで開いている下書きの場合はモーダルを非表示にする。
    if (modalDraft && modalDraft.id === draftId) {
      store.set(modalDraftAtom, undefined);
    }

    const key = draftId;

    openNotification(key);

    const draftDoc = await getDoc(
      companyDoc('drafts', draftId, draftConverter)
    );
    if (!draftDoc.exists()) {
      const errorMessage = `Draft not found in sendMessageFunc@sendMessage. DraftId:${draftId}`;
      console.error(errorMessage);
      Modal.error({
        title: 'メールの送信に失敗しました',
        width: 520,
        content: (
          <>
            <p>
              お手数ですが、画面をリロードして再度お試しください。もし解消されず、エラーが続いた場合はお問い合わせください。
              <br />
              {errorMessage}
            </p>
          </>
        ),
      });
      return;
    }
    const draft = draftDoc.data();

    const payload: Partial<DraftData> = {
      isSending: true,
    };

    const systemStatuses: string[] = [
      MessageStatus.Unprocessed,
      MessageStatus.Processed,
    ];

    if (
      draft.followupAt &&
      draft.status &&
      !systemStatuses.includes(draft.status)
    ) {
      payload.followupStatus = draft.status;
    }

    // 下書きの状態を送信中にする。
    await updateDoc(draft.ref, {
      ...payload,
      updatedAt: serverTimestamp(),
    });

    const cancelStatusUpdate =
      draft.isReply && status !== null
        ? await updateReplyToMessage(draft, status)
        : null;

    // キャンセル時にresolveしなかった場合、sendMessageの処理が5秒待たないとresolveされなくなるので入れています。
    const cancel = async () => {
      mobxStore.cancelableSendingMessageIds =
        mobxStore.cancelableSendingMessageIds.filter(
          (messageId) => messageId !== draftId
        );
      isCanceled = true;

      // 下書きの状態を送信中から元に戻す。
      await updateDoc(draft.ref, {
        isSending: false,
        updatedAt: serverTimestamp(),
      });

      if (cancelStatusUpdate) {
        await cancelStatusUpdate();
      }

      resolve({ cancel: true });
    };

    // 通知にキャンセルボタンを表示させている。
    updateCancellableNotification(key, cancel);

    await sleep(5 * 1000);

    if (isCanceled) return;

    updateSendingNotification(key);

    try {
      await send(draftId);

      if (draft.isReply) {
        await createEvent(draft);
      }

      updateSuccessNotification(key);
      resolve({ cancel: false });
    } catch (e) {
      type SendMessageFuncError = {
        details?: {
          transportError?: SendMessageError;
          traceId?: string;
        };
      };

      const errorDetail = (e as SendMessageFuncError)?.details;

      console.error(
        'sendMessage:',
        {
          draftId: draft.id,
          companyId: mobxStore.signInCompany,
          teamId: draft.teamId,
          inboxId: draft.inboxId,
          inReplyToMessageId: draft.inReplyToMessageId,
          status: status?.statusName,
        },
        errorDetail || null,
        e
      );
      Modal.error({
        title: 'メールの送信に失敗しました',
        width: 520,
        content: (
          <>
            {errorDetail?.transportError && (
              <SendMessageErrorContent error={errorDetail.transportError} />
            )}
            {errorDetail?.traceId && (
              <p>トラッキングID：{errorDetail.traceId}</p>
            )}
          </>
        ),
      });
      cancel();
      notification.close(key);
    }
  });
};

const updateReplyToMessage = async (
  draft: Draft,
  status: FrontMessageStatus | UnknownFrontMessageStatus
) => {
  const { me } = mobxStore;
  const inReplyToMessageRef = draft.inReplyToMessageRef;
  if (!inReplyToMessageRef) {
    const errorMessage = `inReplyToMessageRef is undefined: draftId=${draft.id}`;
    console.error(errorMessage);
    Modal.error({
      title: 'メールの送信に失敗しました',
      width: 520,
      content: (
        <>
          <p>
            お手数ですが、画面をリロードして再度お試しください。もし解消されず、エラーが続いた場合はお問い合わせください。
            <br />
            {errorMessage}
          </p>
        </>
      ),
    });
    return;
  }
  const messageSnapshot = await getDoc(inReplyToMessageRef);
  if (!messageSnapshot.exists()) {
    const errorMessage = `Message not found: draftId=${draft.id}`;
    console.error(errorMessage);
    Modal.error({
      title: 'メールの送信に失敗しました',
      width: 520,
      content: (
        <>
          <p>
            お手数ですが、画面をリロードして再度お試しください。もし解消されず、エラーが続いた場合はお問い合わせください。
            <br />
            {errorMessage}
          </p>
        </>
      ),
    });
    return;
  }
  const replyToMessage = createMessage(
    inReplyToMessageRef,
    messageSnapshot.data()
  );

  const updateStatus = async (
    status: FrontMessageStatus | UnknownFrontMessageStatus
  ) => {
    const firestoreStatus = frontMessageStatusToFirestore(status);
    if (mobxStore.isInThreadView) {
      const snapshot = await mobxStore
        .companyCollection('messages')
        .where('teamId', '==', replyToMessage.teamId)
        .where('threadId', '==', replyToMessage.threadId)
        .where('status', '!=', firestoreStatus)
        .where('deleted', '==', false)
        .get();

      const batch = new WriteBatch();
      snapshot.docs
        .map((doc) => doc.ref)
        .forEach((ref) => {
          batch.update(ref, {
            status: firestoreStatus,
            updatedAt: FieldValue.serverTimestamp(),
          });
        });
      await batch.commit();
    } else {
      await updateDoc(replyToMessage.ref, {
        status: firestoreStatus,
        updatedAt: serverTimestamp(),
      });
    }
  };

  if (status.statusName === '未対応' && status.customStatusId !== null) {
    /**
     * データ整合性の問題になるため、エラーを出力する。
     */
    console.error('「未対応」のcustomStatusId!=null');
  }

  const updateProcessed = updateStatus(status);

  const addProcessedEvent = mobxStore.companyCollection('events').add({
    user: me.id,
    name: me.name,
    teamId: replyToMessage.teamId,
    messageId: replyToMessage.id,
    type: 'status:update:processed',
    text: `${me.name}が「${status.statusName}」に変更しました。`,
    createdAt: FieldValue.serverTimestamp(),
    updatedAt: FieldValue.serverTimestamp(),
  });
  await Promise.all([updateProcessed, addProcessedEvent]);

  logEvent(eventNames.update_status, { status });

  return async () => {
    const updateUnprocessed = updateStatus(
      buildFrontSystemMessageStatus(MessageStatus.Unprocessed)
    );

    const addUnprocessedEvent = mobxStore.companyCollection('events').add({
      user: me.id,
      name: me.name,
      teamId: replyToMessage.teamId,
      messageId: replyToMessage.id,
      type: 'status:update:backlog',
      text: `${me.name}が「${MessageStatus.Unprocessed}」に変更しました。`,
      createdAt: FieldValue.serverTimestamp(),
      updatedAt: FieldValue.serverTimestamp(),
    });
    await Promise.all([updateUnprocessed, addUnprocessedEvent]);
    logEvent(eventNames.update_status, { status: MessageStatus.Unprocessed });
  };
};

const createEvent = async (draft: Draft) => {
  await addDoc(companyCollection('events'), {
    user: mobxStore.me.id,
    name: mobxStore.me.name,
    teamId: draft.teamId,
    messageId: draft.inReplyToMessageId,
    type: 'draft:sent',
    text: `${mobxStore.me.name}が${
      draft.isForwarded ? '転送' : '返信'
    }を送信しました。`,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  });
};

const send = (draftId: string) =>
  sendMessageFunction({
    draftId,
    companyId: mobxStore.signInCompany,
  });
