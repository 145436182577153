import {
  ComponentProps,
  ComponentType,
  createRef,
  forwardRef,
  Ref,
  RefObject,
  SVGProps,
  useEffect,
  useRef,
  useState,
} from 'react';
import { twMerge } from 'tailwind-merge';
import { tv } from 'tailwind-variants';
import styles from './Tabs.module.css';
import { Icon } from './Icon';

export type TabEntry<T> = {
  value: T;
  label: string;
  icon?: ComponentType<SVGProps<SVGSVGElement>>;
};

type Props<T> = Omit<ComponentProps<'div'>, 'children' | 'onChange'> & {
  entries: TabEntry<T>[];
  value: T;
  onChange: (value: T) => void;
  scrollElementClassName?: string;
  tabClassName?: string;
  activeBarClassName?: string;
};

export const Tabs = forwardRef<HTMLDivElement, Props<unknown>>(
  (props, ref): JSX.Element => {
    return <InternalTabs {...props} internalRef={ref} />;
  }
) as <T>(
  p: Props<T> & {
    ref?: Ref<HTMLDivElement>;
  }
) => JSX.Element;

const tabs = tv(
  {
    slots: {
      base: 'relative h-tab max-h-full',
      wrapper: 'relative flex h-full flex-nowrap overflow-auto',
    },
  },
  {
    twMergeConfig: {
      classGroups: {
        h: ['h-tab'],
      },
    },
  }
);

const tab = tv({
  base: 'relative z-0 h-full cursor-pointer select-none whitespace-nowrap bg-transparent px-4 text-sm leading-4 outline-none before:absolute before:inset-x-0 before:inset-y-[4px] before:z-[-1] before:rounded-md before:content-[""] hover:before:bg-sumi-50 focus-visible:before:bg-sumi-50',
  variants: {
    active: {
      true: 'font-bold',
    },
  },
});

const activeBar = tv({
  base: 'absolute bottom-0 left-0 z-10 h-[2px] bg-sea-500 transition-all duration-200',
});

const { base, wrapper } = tabs();

const InternalTabs = <T,>({
  entries,
  value,
  onChange,
  className,
  internalRef,
  scrollElementClassName,
  tabClassName,
  activeBarClassName,
  ...props
}: Props<T> & {
  internalRef: Ref<HTMLDivElement>;
}): JSX.Element => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const tabRefs = useRef<RefObject<HTMLButtonElement>[]>([]);
  const [barPos, setBarPos] = useState<{
    left: number;
    width: number;
  } | null>();
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(false);
  const updateBarPos = () => {
    const activeIndex = entries.map((e) => e.value).indexOf(value);
    if (activeIndex < 0) {
      setBarPos(null);
    }
    const element = tabRefs.current[activeIndex]?.current;
    if (element) {
      const rect = element.getBoundingClientRect();
      setBarPos({
        left: element.offsetLeft,
        width: rect.width,
      });
    } else {
      setBarPos(null);
    }
  };
  const updateButtons = () => {
    const wrapper = wrapperRef.current;
    if (wrapper) {
      setShowLeftButton(wrapper.scrollLeft > 10);
      setShowRightButton(
        wrapper.scrollWidth - (wrapper.clientWidth + wrapper.scrollLeft) > 10
      );
    }
  };
  useEffect(() => {
    updateBarPos();
    updateButtons();
  }, [entries, value]);
  useEffect(() => {
    const current = wrapperRef.current;
    if (!current) {
      return;
    }
    const onWheel = (e: WheelEvent) => {
      if (e.deltaY === 0) {
        return;
      }
      e.preventDefault();
      const amount = e.deltaY / 2;
      current.scrollBy({ left: amount });
    };
    current.addEventListener('wheel', onWheel);
    current.addEventListener('scroll', updateButtons);
    return () => {
      current.removeEventListener('wheel', onWheel);
      current.removeEventListener('scroll', updateButtons);
    };
  }, []);
  return (
    <div className={base({ className })} {...props}>
      <div
        className={twMerge(
          wrapper({
            className: [styles.hideScrollbar, scrollElementClassName],
          })
        )}
        ref={wrapperRef}
      >
        {barPos && (
          <div
            className={activeBar({ className: activeBarClassName })}
            style={{
              transform: `translateX(${barPos.left}px)`,
              width: barPos.width,
            }}
          />
        )}
        {entries.map((entry, i) => {
          const ref = createRef<HTMLButtonElement>();
          tabRefs.current[i] = ref;
          return (
            <button
              key={i}
              type={'button'}
              className={tab({
                active: value === entry.value,
                className: tabClassName,
              })}
              onClick={() => onChange(entry.value)}
              data-active={value === entry.value}
              ref={ref}
            >
              {entry.label}
              {entry?.icon && <Icon icon={entry.icon} />}
            </button>
          );
        })}
      </div>
      {showLeftButton && (
        <button
          className={twMerge(
            'absolute left-0 top-0 flex h-full w-6 cursor-pointer select-none items-center justify-center p-0 text-[1rem] text-sm font-bold transition-all hover:w-8',
            styles.bgLeft
          )}
          onClick={() =>
            wrapperRef.current?.scrollBy({ left: -150, behavior: 'smooth' })
          }
        >
          &lt;
        </button>
      )}
      {showRightButton && (
        <button
          className={twMerge(
            'absolute right-0 top-0 flex h-full w-6 cursor-pointer select-none items-center justify-center p-0 text-[1rem] font-bold transition-all hover:w-8',
            styles.bgRight
          )}
          onClick={() =>
            wrapperRef.current?.scrollBy({ left: 150, behavior: 'smooth' })
          }
        >
          &gt;
        </button>
      )}
    </div>
  );
};
