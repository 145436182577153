import { ReactNode } from 'react';
import { Icon } from '../../basics';
import { Close, Search } from '../../icons';

type Props = {
  show: boolean;
  onResetFilter: () => void;
  children: ReactNode;
};

export const SidebarSearchOverlay = ({
  show,
  onResetFilter,
  children,
}: Props) => {
  if (!show) {
    return <>{children}</>;
  }
  return (
    <div className="relative h-full overflow-hidden">
      <div {...{ inert: '' }}>{children}</div>
      <div className="absolute inset-0 z-10 flex flex-col items-center justify-center gap-2 bg-sea-50 text-sm">
        <Icon icon={Search} size={100} className="text-sumi-600" />
        <div className="select-none text-lg">検索中</div>
        <button
          type="button"
          className="relative left-[calc((12px_+_theme(spacing.1))_/_4)] flex cursor-pointer select-none items-center gap-1 bg-transparent p-0 text-sea-500"
          onClick={() => onResetFilter()}
        >
          <span>検索を解除</span>
          <Icon icon={Close} size={12} />
        </button>
      </div>
    </div>
  );
};
