import { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { SearchFilterData, searchFilterOpenAtom } from '../MainSearch';
import { SearchQuery } from '../../../../store/search';
import { useStore } from '../../../../hooks/useStore';
import moment from 'moment';
import { Options } from '../../../../components/forms';
import { useForm } from 'react-hook-form';
import { atom, useAtom, useSetAtom } from 'jotai';
import { getSearchRange, searchRangeToQuery } from './searchRange';
import { getFilterTagOptions, getFilterTargetTags } from './tag';
import { getFilterMemberOptions } from './member';

export const Tray = {
  messages: '受信メール',
  sent: '送信メール',
  deleted: 'ゴミ箱',
} as const;
export type Tray = keyof typeof Tray;

export type SearchRange =
  | {
      type: 'allInboxes';
    }
  | {
      type: 'teamInboxes';
      teamId: string;
    }
  | {
      type: 'inbox';
      teamId: string;
      inboxId: string;
    };

type UseSearchResult = {
  filterForm: ReturnType<typeof useForm<SearchFilterData>>;
  trayOptions: Options<Tray>;
  tagOptions: Options<string>;
  memberOptions: Options<string | null>;
  search: () => void;
};

export const trayOptions: Options<Tray> = Object.entries(Tray).map(
  ([value, label]) => ({
    value: value as Tray,
    label,
  })
);

const resetAtom = atom<(() => void) | undefined>(undefined);
const dirtyAtom = atom(false);
export const dirtyResetAtom = atom<boolean, [], void>(
  (get) => get(dirtyAtom),
  (get) => get(resetAtom)?.()
);

const returnPathAtom = atom<string>();

export const useSearch = (onSearch?: () => void): UseSearchResult => {
  const setReset = useSetAtom(resetAtom);
  const setDirty = useSetAtom(dirtyAtom);
  const [returnPath, setReturnPath] = useAtom(returnPathAtom);
  const setFilterOpen = useSetAtom(searchFilterOpenAtom);

  const history = useHistory();
  const store = useStore();
  const { searchStore } = store;
  const { query } = searchStore;
  const match = useRouteMatch('/search/:tray/:query');
  const params = (match ? match.params : {}) as {
    tray?: Tray;
    query?: string;
  };

  const filterToQuery = (filter: SearchFilterData): SearchQuery => {
    return {
      ...query,
      ...filterFormToQuery(filter),
    };
  };

  const filterForm = useForm<SearchFilterData>({
    defaultValues: {
      keyword: '',
      searchRange: { type: 'allInboxes' },
      tray: 'messages',
      status: '',
      from: '',
      to: '',
      subjectOrText: '',
      tag: '',
      assignee: '',
      after: null,
      before: null,
      hasAttachments: false,
      attachmentsFilename: '',
    },
  });

  const onSubmit = (data: SearchFilterData) => {
    if (!searchStore.inSearch) {
      const path = window.location.href.substring(
        window.location.origin.length
      );
      setReturnPath(path);
    }
    history.push(
      `/search/${data.tray}/${encodeURIComponent(
        JSON.stringify(filterToQuery(data))
      )}`
    );
    onSearch?.();
  };

  const updateQuery = () => {
    const { getValues, setValue } = filterForm;
    const range = getValues('searchRange');

    const tagId = getValues('tag');
    const tagOptions = getFilterTargetTags(range, store);
    const foundTag = tagOptions.find((t) => t.id === tagId);
    if (tagId) {
      if (foundTag) {
        setValue('tag', foundTag.isInbox ? '' : tagId);
      } else {
        setValue('tag', '');
      }
    }
  };

  useEffect(() => {
    setReset(() => () => {
      filterForm.reset();
      searchStore.query = {};
      setFilterOpen(false);

      if (!searchStore.inSearch) {
        return;
      }

      const toPath = returnPath ?? '/me/drafts';
      history.push(toPath);
    });
  }, [filterForm, searchStore.inSearch, returnPath, history]);

  useEffect(() => {
    setDirty(filterForm.formState.isDirty);
  }, [filterForm.formState.isDirty]);

  useEffect(() => {
    const { getValues } = filterForm;
    const tray = getValues('tray');
    const range = getValues('searchRange');
    const query = searchStore.query;
    if (range.type === 'allInboxes' || tray === 'sent') {
      query.inbox = false;
    }
    if (range.type !== 'inbox') {
      query.teamIds = undefined;
      query.inboxId = undefined;
    }
    searchStore.query = query;
    updateQuery();
  }, [filterForm.watch('searchRange'), filterForm.watch('tray')]);

  useEffect(() => {
    const pathname = history.location.pathname;
    const { tray, query: stringQuery } = params;
    if (stringQuery) {
      let query: SearchQuery | undefined;
      try {
        query = JSON.parse(decodeURIComponent(stringQuery)) as SearchQuery;
        const { after, before, keywords, tags, ...otherQuery } = query;
        filterForm.reset(
          {
            tray: tray,
            ...otherQuery,
            keyword: keywords?.join(' '),
            searchRange: getSearchRange(query),
            tag: tags?.at(0) ?? undefined,
            after: after ? new Date(after) : null,
            before: before ? new Date(before) : null,
          },
          {
            keepDefaultValues: true,
          }
        );
      } catch (e) {}
    }

    if (pathname.startsWith('/search')) {
      searchStore.inSearch = true;
      return;
    } else {
      searchStore.inSearch = false;
    }
    updateQuery();
  }, [params.tray, params.query]);

  const searchRange = filterForm.watch('searchRange');
  return {
    filterForm,
    search: filterForm.handleSubmit(onSubmit),
    trayOptions,
    tagOptions: getFilterTagOptions(searchRange, store),
    memberOptions: getFilterMemberOptions(searchRange, store),
  };
};

export const filterFormToQuery = (
  filter: Pick<SearchFilterData, 'searchRange' | 'tray'> &
    Omit<Partial<SearchFilterData>, 'searchRange' | 'tray'>
) => {
  return {
    ...searchRangeToQuery(filter.searchRange),
    keywords: filter.keyword
      ? filter.keyword.replace('　', ' ').split(' ')
      : undefined,
    status: filter.status || undefined,
    from: filter.from || undefined,
    to: filter.to || undefined,
    subjectOrText: filter.subjectOrText || undefined,
    tags: filter.tag ? [filter.tag] : undefined,
    assignee: filter.assignee === '' ? undefined : filter.assignee,
    after: filter.after ? moment(filter.after).format('YYYY-MM-DD') : undefined,
    before: filter.before
      ? moment(filter.before).format('YYYY-MM-DD')
      : undefined,
    hasAttachments: filter.hasAttachments || undefined,
    attachmentsFilename: filter.attachmentsFilename || undefined,
  };
};
