import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  Comment,
  commentConverter,
  Message,
  messageConverter,
  MessageLike,
  messageLikeConverter,
  Sent,
  sentConverter,
} from 'lib';
import { Draft, draftConverter } from '../../../../firestore/entity/draft';
import {
  companyCollection,
  companyDoc,
  registerUnsubscribe,
} from '../../../../firestore';
import { useSubscribeDocument } from '../../../../hooks/firestore/subscription';
import { onSnapshot, query, Unsubscribe, where } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { subscribeQueryInArray } from '../../../../utils/firebase';
import { Lock, lockConverter } from '../../../../firestore/entity/lock';
import { Event, eventConverter } from '../../../../firestore/entity/event';
import * as Sentry from '@sentry/react';

type Conversation =
  | {
      loading: true;
    }
  | {
      loading: false;
      headerMessage: MessageLike;
      messages: Message[];
      sentMessages: Sent[];
      drafts: Draft[];
      locks: Lock[];
      events: Event[];
      comments: Comment[];
    };

export const useConversation = (
  threadView: boolean,
  messageId: string,
  onAddSentMessage: (sentMessage: Sent) => void,
  onAddComment: (comment: Comment) => void
): Conversation => {
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState<Message[]>();
  const [sentMessages, setSentMessages] = useState<Sent[]>();
  const [drafts, setDrafts] = useState<Draft[]>();
  const [locks, setLocks] = useState<Lock[]>();
  const [events, setEvents] = useState<Event[]>();
  const [comments, setComments] = useState<Comment[]>();
  // メッセージに紐づいたデータを取得する際に使用
  const [messageIds, setMessageIds] = useState<string[]>([]);

  const [, headerMessage] = useSubscribeDocument(
    companyDoc(
      threadView ? 'threads' : 'messages',
      messageId,
      messageLikeConverter
    )
  );
  const location = useLocation();
  const deleted = location.pathname.includes('/deleted');

  useEffect(() => {
    if (threadView && headerMessage) {
      return registerUnsubscribe(
        uuidv4(),
        onSnapshot(
          query(
            companyCollection('messages', messageConverter),
            where('teamId', '==', headerMessage.teamId),
            where('threadId', '==', headerMessage.id),
            where('deleted', '==', deleted)
          ),
          (snapshot) => {
            if (snapshot.size) {
              const messages = snapshot.docs.map((doc) => doc.data());
              messages.sort((a, b) => a.date.diff(b.date));
              setMessages(messages);
              if (
                messages.length !== messageIds.length ||
                messages.some((message) => !messageIds.includes(message.id))
              ) {
                setMessageIds(messages.map((message) => message.id));
              }
            } else {
              Sentry.withScope((scope) => {
                scope.setTag('thread', headerMessage.id);
                console.error('Messages not found in thread');
              });
            }
          }
        )
      );
    }
  }, [threadView, headerMessage?.id, headerMessage?.teamId, deleted]);

  useEffect(() => {
    if (!threadView && headerMessage) {
      setMessages([headerMessage.asMessage()]);
      if (messageIds[0] !== headerMessage.id) {
        setMessageIds([headerMessage.id]);
      }
    }
  }, [threadView, headerMessage]);

  useEffect(() => {
    if (!headerMessage || !messages?.length) {
      return;
    }

    const teamId = headerMessage.teamId;
    const unsubscribes: Unsubscribe[] = [];

    const messageIdField = threadView ? 'threadId' : 'inReplyToMessageId';
    unsubscribes.push(
      registerUnsubscribe(
        uuidv4(),
        onSnapshot(
          query(
            companyCollection('sent', sentConverter),
            where('teamId', '==', headerMessage.teamId),
            where(messageIdField, '==', headerMessage.id)
          ),
          (snapshot) => {
            setSentMessages(snapshot.docs.map((doc) => doc.data()));
            snapshot.docChanges().forEach((change) => {
              if (!loading && change.type === 'added') {
                onAddSentMessage(change.doc.data());
              }
            });
          }
        )
      )
    );

    unsubscribes.push(
      registerUnsubscribe(
        uuidv4(),
        subscribeQueryInArray(
          query(
            companyCollection('drafts', draftConverter),
            where('teamId', '==', teamId)
          ),
          'inReplyToMessageId',
          messageIds,
          (snapshot, docs) => {
            setDrafts(docs.map((doc) => doc.data()));
          }
        )
      )
    );

    unsubscribes.push(
      registerUnsubscribe(
        uuidv4(),
        subscribeQueryInArray(
          query(
            companyCollection('locks', lockConverter),
            where('teamId', '==', teamId)
          ),
          'messageId',
          messageIds,
          (snapshot, docs) => {
            setLocks(docs.map((doc) => doc.data()));
          }
        )
      )
    );

    unsubscribes.push(
      registerUnsubscribe(
        uuidv4(),
        subscribeQueryInArray(
          query(
            companyCollection('events', eventConverter),
            where('teamId', '==', teamId)
          ),
          'messageId',
          messageIds,
          (snapshot, docs) => {
            setEvents(docs.map((doc) => doc.data()));
          }
        )
      )
    );

    unsubscribes.push(
      registerUnsubscribe(
        uuidv4(),
        subscribeQueryInArray(
          query(
            companyCollection('comments', commentConverter),
            where('teamId', '==', teamId)
          ),
          'messageId',
          messageIds,
          (snapshot, docs) => {
            setComments(docs.map((doc) => doc.data()));
            snapshot.docChanges().forEach((change) => {
              if (change.type === 'added') {
                onAddComment(change.doc.data());
              }
            });
          }
        )
      )
    );

    return () => unsubscribes.forEach((f) => f());
  }, [threadView, headerMessage?.teamId, messageIds]);

  if (
    headerMessage &&
    messages?.length &&
    sentMessages &&
    drafts &&
    locks &&
    events &&
    comments
  ) {
    if (loading) {
      setLoading(false);
    }
    return {
      loading: false,
      headerMessage:
        threadView && deleted ? headerMessage.toDeletedThread() : headerMessage,
      messages,
      sentMessages,
      drafts,
      locks,
      events,
      comments,
    };
  }
  return {
    loading: true,
  };
};
