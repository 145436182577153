import { Controller, useForm } from 'react-hook-form';
import { SettingPageDrawer } from '../../../common/SettingPageDrawer/SettingPageDrawer';
import { InputGroup } from '../../../../../components/forms/InputGroup/InputGroup';
import { DatePicker, TimePicker } from 'antd';
import { Button } from '../../../../../components/basics';
import moment from 'moment';
import { AutoReplyData } from '../../../../../firestore/entity/inbox';
import { Input, Select } from '../../../../../components/forms';
import { Textarea } from '../../../../../components/forms/Textarea/Textarea';
import { useConfirmDialog } from '../../../../../hooks/confirmDialog';
import { Timestamp } from 'firebase/firestore';

export type AutoReplyForm = Omit<
  AutoReplyData,
  'teamID' | 'updatedAt' | 'createdAt' | 'activeTime'
> & {
  activeTime:
    | {
        type: 'always';
      }
    | {
        type: 'period';
        start: Date;
        end: Date;
      };
};

type Props = {
  inboxes: { id: string; email: string }[];
  autoReply?: AutoReplyData;
  onSave: (data: AutoReplyForm) => Promise<void>;
  onDelete?: () => Promise<void>;
  open: boolean;
  readonly: boolean;
  onOpenChange: (open: boolean) => void;
};

type TimeError = {
  start?: {
    message: string;
  };
  end?: {
    message: string;
  };
};

export const AutoReplyEditDrawer = ({
  inboxes,
  autoReply,
  onSave,
  onDelete,
  open,
  onOpenChange,
  readonly,
}: Props) => {
  const {
    handleSubmit,
    register,
    control,
    watch,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm<AutoReplyForm>({
    defaultValues: {
      inboxId: autoReply?.inboxId ?? '',
      title: autoReply?.title ?? '',
      messageBody: autoReply?.messageBody ?? '',
      enabled: autoReply?.enabled ?? true,
      activeTime:
        autoReply?.activeTime.type === 'period'
          ? {
              type: 'period',
              start: secToDate(autoReply.activeTime.start.seconds),
              end: secToDate(autoReply.activeTime.end.seconds),
            }
          : { type: 'always' },
      activeTimeOfDay: autoReply?.activeTimeOfDay ?? { type: 'always' },
    },
  });

  const showDialog = useConfirmDialog();

  const activeTimeType = watch('activeTime.type');
  const activeTimeOfDayType = watch('activeTimeOfDay.type');

  const onSubmit = async (update: AutoReplyForm) => {
    const normalizedActiveTime: AutoReplyForm['activeTime'] =
      update.activeTime.type === 'always'
        ? { type: 'always' }
        : update.activeTime;
    const normalizedActiveTimeOfDay: AutoReplyForm['activeTimeOfDay'] =
      update.activeTimeOfDay.type === 'always'
        ? { type: 'always' }
        : update.activeTimeOfDay;
    const data: AutoReplyForm = {
      ...update,
      activeTime: normalizedActiveTime,
      activeTimeOfDay: normalizedActiveTimeOfDay,
    };
    await onSave(data);
  };

  const onClickDelete = async () => {
    if (!onDelete) {
      return;
    }
    showDialog({
      title: `自動返信を削除しますか？`,
      description: '一度削除すると元に戻せません',
      okType: 'danger',
      okText: '削除',
      onOk: onDelete,
    });
  };

  const validateActiveTime = (_v: Date | undefined) => {
    if (activeTimeType === 'always') {
      return true;
    }
    const start = getValues('activeTime.start');
    const end = getValues('activeTime.end');
    if (!start || !end) {
      return '期間を指定してください';
    }

    if (start.getTime() > end.getTime()) {
      return '開始には終了より前の日付を入力してください';
    }

    return true;
  };

  const validateActiveTimeOfDay = (v: string | null) =>
    activeTimeOfDayType === 'always' || v ? true : '時間を指定してください';

  const activeTimeError = errors.activeTime as TimeError | undefined;
  const activeTimeErrorMessage = (
    activeTimeError?.start || activeTimeError?.end
  )?.message;

  const activeTimeOfDayError = errors.activeTimeOfDay as TimeError | undefined;
  const activeTimeOfDayErrorMessage = (
    activeTimeOfDayError?.start || activeTimeOfDayError?.end
  )?.message;

  return (
    <SettingPageDrawer
      title={autoReply ? '自動返信編集' : '自動返信作成'}
      open={open}
      onOpenChange={onOpenChange}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-4 text-sm"
      >
        <InputGroup
          label="タイトル"
          errorMessage={errors.title?.message}
          required
        >
          <Input
            {...register('title', {
              required: 'タイトルを入力してください',
              disabled: readonly,
            })}
            className="w-full resize-y rounded-lg border border-sumi-300 p-2 outline-none focus-visible:border-sea-500 disabled:text-sumi-500"
          />
        </InputGroup>
        <InputGroup
          label="期間の指定"
          errorMessage={activeTimeErrorMessage}
          required
        >
          <>
            <div className="mb-4">
              <label className="flex items-center">
                <input
                  {...register('activeTime.type', {
                    required: true,
                    disabled: readonly,
                  })}
                  type="radio"
                  value="always"
                  className="mr-2"
                />
                すべての期間
              </label>
            </div>
            <div className="mb-4">
              <label className="mb-4 flex items-center">
                <input
                  {...register('activeTime.type', {
                    required: true,
                    disabled: readonly,
                  })}
                  type="radio"
                  value="period"
                  className="mr-2"
                />
                特定の期間
              </label>
              <div className="ml-6 flex flex-col gap-4">
                <div className="flex items-center gap-2">
                  <div className="w-8">開始</div>
                  <Controller
                    name="activeTime.start"
                    control={control}
                    rules={{
                      validate: validateActiveTime,
                    }}
                    render={({ field: { value, onChange } }) => (
                      <DatetimePicker
                        value={value ? new Date(`${value}`) : undefined}
                        onChange={onChange}
                        disabled={readonly || activeTimeType !== 'period'}
                      />
                    )}
                  />
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-8">終了</div>
                  <Controller
                    name="activeTime.end"
                    control={control}
                    rules={{
                      validate: validateActiveTime,
                    }}
                    render={({ field: { value, onChange } }) => (
                      <DatetimePicker
                        value={value ? new Date(`${value}`) : undefined}
                        onChange={onChange}
                        disabled={readonly || activeTimeType !== 'period'}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        </InputGroup>
        <InputGroup
          label="時間の指定"
          errorMessage={activeTimeOfDayErrorMessage}
          required
        >
          <>
            <div className="mb-4">
              <label className="flex items-center">
                <input
                  {...register('activeTimeOfDay.type', {
                    required: true,
                    disabled: readonly,
                  })}
                  type="radio"
                  value="always"
                  className="mr-2"
                />
                すべての時間
              </label>
            </div>
            <div className="mb-4">
              <label className="mb-4 flex items-center">
                <input
                  {...register('activeTimeOfDay.type', {
                    required: true,
                    disabled: readonly,
                  })}
                  type="radio"
                  value="period"
                  className="mr-2"
                />
                特定の時間
              </label>
              <div className="ml-6 flex flex-col gap-4">
                <div className="flex items-center gap-2">
                  <div className="w-8">開始</div>
                  <Controller
                    name="activeTimeOfDay.start"
                    control={control}
                    rules={{
                      validate: validateActiveTimeOfDay,
                    }}
                    render={({ field: { value, onChange } }) => (
                      <TimePicker
                        placeholder="時間"
                        format="HH:mm"
                        value={value ? moment(value, 'HH:mm') : undefined}
                        onChange={(time) =>
                          onChange(time ? time.format('HH:mm') : undefined)
                        }
                        disabled={readonly || activeTimeOfDayType !== 'period'}
                      />
                    )}
                  />
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-8">終了</div>
                  <Controller
                    name="activeTimeOfDay.end"
                    control={control}
                    rules={{
                      validate: validateActiveTimeOfDay,
                    }}
                    render={({ field: { value, onChange } }) => (
                      <TimePicker
                        placeholder="時間"
                        format="HH:mm"
                        value={value ? moment(value, 'HH:mm') : undefined}
                        onChange={(time) =>
                          onChange(time ? time.format('HH:mm') : undefined)
                        }
                        disabled={readonly || activeTimeOfDayType !== 'period'}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        </InputGroup>
        <InputGroup
          label="適用するメールアドレス"
          errorMessage={errors.inboxId?.message}
          required
        >
          <Controller
            name="inboxId"
            control={control}
            rules={{
              required: 'メールアドレスを入力してください',
            }}
            render={({ field: { value, onChange } }) => (
              <Select
                value={value}
                onChange={(value) => onChange(value)}
                disabled={readonly}
                className="w-full"
                options={inboxes.map((inbox) => ({
                  value: inbox.id,
                  label: inbox.email,
                }))}
              />
            )}
          />
        </InputGroup>
        <InputGroup label="自動返信の本文">
          <Controller
            name="messageBody"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Textarea
                value={value}
                onChange={onChange}
                className="w-full"
                minRows={8}
                maxRows={8}
                disabled={readonly}
              />
            )}
          ></Controller>
        </InputGroup>
        <div className="flex gap-4">
          {autoReply ? (
            <>
              <Button type="submit" disabled={readonly || isSubmitting}>
                更新
              </Button>
              <Button
                color="danger"
                disabled={readonly || isSubmitting}
                onClick={onClickDelete}
              >
                削除
              </Button>
            </>
          ) : (
            <Button type="submit" disabled={readonly || isSubmitting}>
              作成
            </Button>
          )}
        </div>
      </form>
    </SettingPageDrawer>
  );
};

type DatetimePickerProps = {
  value: Date | undefined;
  onChange: (value: Date | undefined) => void;
  disabled: boolean;
};

const DatetimePicker = ({ value, onChange, disabled }: DatetimePickerProps) => {
  return (
    <div className="flex gap-2">
      <DatePicker
        placeholder="日付"
        format="YYYY-MM-DD"
        value={value ? moment(value, 'YYYY-MM-DD') : undefined}
        onChange={(date) => {
          if (value) {
            onChange(
              date
                ? new Date(
                    `${moment(date).format('YYYY-MM-DD')} ${moment(
                      value
                    ).format('HH:mm')}`
                  )
                : undefined
            );
          } else {
            onChange(date?.toDate() ?? undefined);
          }
        }}
        disabled={disabled}
      />
      <TimePicker
        placeholder="時間"
        format="HH:mm"
        value={value ? moment(value, 'HH:mm') : undefined}
        onChange={(time) => {
          if (value) {
            onChange(
              time
                ? new Date(
                    `${moment(value).format('YYYY-MM-DD')} ${time.format(
                      'HH:mm'
                    )}`
                  )
                : moment(value, 'YYYY-MM-DD').toDate()
            );
          } else {
            onChange(time.toDate());
          }
        }}
        disabled={disabled}
        allowClear={false}
      />
    </div>
  );
};

const secToDate = (sec: number): Date => new Timestamp(sec, 0).toDate();
