import { MessageRecipient } from './MessageRecipient';
import { Message, User } from 'lib';
import { HeaderContactBase } from '../HeaderContactBase';
import { HeaderFromName } from './HeaderFromName';

type Props = {
  user: User | undefined;
  message: Message;
};

const MessageHeaderContact = ({ user, message }: Props) => {
  return (
    <HeaderContactBase
      user={user}
      iconName={message.fromIconName}
      contactElement={
        <HeaderFromName teamId={message.teamId} email={message.from.value[0]} />
      }
      recipientElement={
        <MessageRecipient
          subject={message.subject}
          mailAddresses={{
            from: message.from.value,
            to: message.to?.value,
            cc: message.cc?.value,
            bcc: message.bcc?.value,
          }}
        />
      }
    />
  );
};

export default MessageHeaderContact;
