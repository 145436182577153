import { Button } from 'antd';
import React from 'react';
import { serverTimestamp, updateDoc } from 'firebase/firestore';
import { SPBackButton } from '../Conversations/Conversation/SPBackButton';
import { useStore } from '../../../hooks/useStore';
import {
  LineAccount,
  LineContact,
  LineThread,
  LineThreadStatus,
} from '../../../firestore/entity/line';

type Props = {
  lineAccount: LineAccount;
  lineThread: LineThread;
  lineContacts: LineContact[];
};

export const LineConversationHeader = ({
  lineAccount,
  lineThread,
  lineContacts,
}: Props): JSX.Element => {
  const store = useStore();

  const updateStatusToProcessed = async () => {
    if (!lineThread) {
      return;
    }
    await updateDoc(lineThread.ref, {
      status: LineThreadStatus.Processed,
      updatedAt: serverTimestamp(),
    });
  };

  return (
    <div className="flex flex-col gap-1 bg-sumi-50 p-4">
      <SPBackButton
        backLink={`/teams/${lineAccount.teamId}/lineaccounts/${lineAccount.id}/${store.selectedMessageView}`}
      />
      <div className="text-sm">{lineAccount.name}</div>
      <div className="flex items-center justify-between">
        <div className="text-lg font-bold">
          {lineContacts.map((c) => c.displayName).join(', ')}
        </div>
        <div>
          <Button
            icon="check"
            type={
              lineThread.status === LineThreadStatus.Unprocessed
                ? 'dashed'
                : 'default'
            }
            onClick={updateStatusToProcessed}
            disabled={lineThread.status === LineThreadStatus.Processed}
          >
            {lineThread.status === LineThreadStatus.Unprocessed
              ? '対応済みにする'
              : '対応済み'}
          </Button>
        </div>
      </div>
    </div>
  );
};
